import { Component, OnInit, NgZone, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import {
  PurchaseService,
  CapacitorService,
  FirebaseService,
  HttpService,
  EventsService,
  IonicService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-premium',
  templateUrl: './premium.page.html',
  styleUrls: ['./premium.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PremiumPage implements OnInit {

  data: any;
  maximum: any = [];
  account: any = [];
  packages: any = [];
  loaded: boolean = false;
  spinner: boolean = true;
  features: boolean = false;
  reviews: boolean = false;
  feature: string = 'premium';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalPremium: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public purchase: PurchaseService,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Premium');
    this.events.subscribeEvent('premium', () => { this.getData(); });
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('premium');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData(sort: string = '', cache: boolean = false) {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/premium');
      this.packages = this.data['packages'];
      this.account = this.data['account'][0];
      this.maximum = this.data['maximum'][0];
      this.purchase.user = this.data['account'][0]['purchase'];
      this.purchase.active = this.data['packages'][0]['active'];
      await this.purchase.initPurchase(this.account['purchase']);
      this.spinner = false;
      this.loaded = true;
      this.updatePackages();
    });
  }

  //----------------------------------------------------------------------------
  // Get Server
  //----------------------------------------------------------------------------

  async updatePackages() {
    this.zone.run(async () => {
      this.packages = await this.purchase.getPackages(this.packages);
      this.ref.detectChanges();
    });
  }

  //----------------------------------------------------------------------------
  // Open Reviews
  //----------------------------------------------------------------------------

  async openReviews() {
    this.firebase.setEvent('premium_reviews');
    this.capacitor.openBrowser('https://ibucket.app/reviews/');
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalPremium.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
      await this.ionic.setTimeout(500);
      await this.modalPremium.dismiss();
      this.events.refreshPages();
    }
  }
}