import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  FirebaseService,
  IonicService,
  FriendsService,
} from '../../services';

@Component({
  standalone: false,
  selector: 'app-profile',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.scss'],
})
export class PassportComponent implements OnInit {

  @Input() data: any = [];
  @Input() spinner: boolean = false;
  @Input() type: any;

  progress: number = 100;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public firebase: FirebaseService,
    public friends: FriendsService,
    public ionic: IonicService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    await this.ionic.setTimeout(1000);
    this.progress = 100 - ((this.data?.progress / 100) * 100);
  }

  //----------------------------------------------------------------------------
  // Add Friend
  //----------------------------------------------------------------------------

  async addFriend() {
    this.firebase.setEvent('friend_add');
    this.friends.addFriend(this.data?.id);
    this.spinner = true;
    this.data.follow = true;
    this.data.requested = true;
    this.spinner = false;
  }

  //----------------------------------------------------------------------------
  // Remove Friend
  //----------------------------------------------------------------------------

  async removeFriend() {
    this.firebase.setEvent('friend_remove');
    this.friends.removeFriend(this.data?.id);
    this.spinner = true;
    this.data.follow = false;
    this.spinner = false;
  }
}
