<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Assistant' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="purchaseTokens()" class="component warning" *ngIf="data">
        <ion-icon src="/assets/icons/color/stars-02.svg" *ngIf="!purchasing"></ion-icon>
        <ion-label *ngIf="!purchasing">{{ data?.tokens }}</ion-label>
        <ion-spinner name="dots" *ngIf="purchasing"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Loading -->

  <ion-grid *ngIf="!data">
    <ion-list class="padding">
      <ion-skeleton-text class="default" [animated]="true"></ion-skeleton-text>
    </ion-list>
  </ion-grid>

  <!-- Information -->
  
  <ion-grid *ngIf="data && !generating && !completed && data?.verified && data?.tokens != 0">
    <ion-list class="padding">
      <div class="box">
        <div class="title">
          <img src="/assets/imgs/kai.png" width="25" height="25" />
          {{ 'AssistantList' | translate }}
        </div>
        <div class="text" *ngIf="type == 'goal'">
          {{ 'AssistantText' | translate }} {{ 'AssistantGoalExample' | translate }}
        </div>
        <div class="text" *ngIf="type == 'bucket'">
          {{ 'AssistantText' | translate }} {{ 'AssistantListExample' | translate }}
        </div>
        <div class="text" *ngIf="type == 'trip'">
          {{ 'AssistantText' | translate }} {{ 'AssistantTripExample' | translate }}
        </div>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Generating -->

  <ion-grid *ngIf="data && (generating || completed)">
    <ion-list class="padding">
      <div class="box">
        <div class="title">
          <img src="/assets/imgs/kai.png" width="25" height="25" />
          {{ 'AssistantGenerating' | translate }}
        </div>
        <div class="text">
          {{ 'AssistantGeneratingText' | translate }}
        </div>
        <button ion-button class="cta big mediumgrey fullwidth" *ngIf="!completed">
          <ion-spinner name="dots"></ion-spinner>
          {{ 'AssistantWait' | translate }}
        </button>
        <button ion-button class="cta big green fullwidth left" (click)="closePage()" *ngIf="completed">
          <ion-icon src="/assets/icons/color/check.svg" color="white"></ion-icon>
          {{ 'AssistantGenerated' | translate }}
        </button>
        <div class="items" *ngIf="completed">
          <div class="result" *ngFor="let item of result">
            <ion-icon src="/assets/icons/color/circle.svg"></ion-icon> {{ item?.title }}
          </div>
        </div>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Verify -->

  <ion-grid *ngIf="data && !generating && !completed && !data?.verified && data?.tokens != 0">
    <ion-list class="padding">
      <div class="box">
        <div class="title">
          <img src="/assets/imgs/kai.png" width="25" height="25" />
          {{ 'Verify' | translate }}
        </div>
        <div class="text">
          {{ 'AssistantVerifyText' | translate }}
        </div>
        <button ion-button class="cta big blue fullwidth left" (click)="openLogin()">
          {{ 'AssistantVerifyNow' | translate }}
          <span class="right"><ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon> +1</span>
        </button>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Purchase -->

  <ion-grid *ngIf="data && !generating && !completed && data?.verified && data?.tokens == 0">
    <ion-list class="padding">
      <div class="box">
        <div class="title">
          <img src="/assets/imgs/kai.png" width="25" height="25" />
          {{ 'AssistantEmpty' | translate }}
        </div>
        <div class="text">
          {{ 'AssistantEmptyText' | translate }}
        </div>
        <button ion-button class="cta big mediumgrey fullwidth left" *ngIf="purchasing">
          <ion-spinner name="dots"></ion-spinner>
          {{ 'AssistantWait' | translate }}
        </button>
        <button ion-button class="cta big green fullwidth left" *ngIf="!purchasing" (click)="purchaseTokens()">
          <ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon> {{ 'AssistantPurchase' | translate }}
          <span class="right">
            <ion-spinner name="dots" *ngIf="!data?.packages[0]?.price"></ion-spinner>
            {{ data?.packages[0]?.price }}
          </span>
        </button>
      </div>
    </ion-list>
  </ion-grid>

  <!-- List -->

  <ion-grid *ngIf="data && !generating && !completed && data?.verified && data?.tokens != 0">
    <ion-list class="group">
      <app-item type="manage" [label]="data?.view?.title ? data?.view?.title : 'Title'" [sublabel]="data?.view?.title ? 'Title' : ''" [data]="data?.view?.title" value="title" icon="edit-04.svg" (click)="setTitle()" *ngIf="type == 'goal' || type == 'bucket'"></app-item>
      <app-item type="manage" [label]="data?.view?.categoryTitle ? data?.view?.categoryTitle : 'Category'" [sublabel]="data?.view?.categoryTitle ? 'Category' : ''" [data]="data?.view?.categoryTitle" value="category" icon="folder.svg" (click)="setCategory()" *ngIf="type == 'bucket'"></app-item>
      <app-item type="manage" [label]="data?.view?.activityTitle ? data?.view?.activityTitle : 'Activity'" [sublabel]="data?.view?.activityTitle ? 'Activity' : ''" [data]="data?.view?.activityTitle" value="activity" icon="hearts.svg" (click)="setActivity()" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [label]="data?.view?.placeTitle ? data?.view?.placeTitle : 'Place'" [sublabel]="data?.view?.placeTitle ? 'Place' : ''" [data]="data?.view?.placeTitle" value="place" icon="marker-pin-01.svg" (click)="setPlace()" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [label]="data?.view?.days ? data?.view?.days : 'Days'" [sublabel]="data?.view?.days ? 'Days' : ''" [data]="data?.view?.days" value="days" icon="calendar-date.svg" (click)="setDays()" *ngIf="type == 'trip'"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Button -->

  <ion-grid *ngIf="data && !generating && !completed && data?.verified && data?.tokens != 0">
    <ion-list class="padding">
      <button ion-button class="cta big orange fullwidth left" (click)="generateItems('list')">
        {{ 'AssistantGenerate' | translate }}
        <span class="right"><ion-icon src="/assets/icons/color/stars-02.svg"></ion-icon> 1</span>
      </button>
    </ion-list>
  </ion-grid>

</ion-content>