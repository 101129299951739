<ion-backdrop [class.visible]="fab?.activated" [tappable]="false" *ngIf="backdrop"></ion-backdrop>

<ion-fab #fab class="tabs" [class.tabbar]="bottom" vertical="bottom" slot="fixed" *ngIf="data?.type != 'user'">

  <ion-fab-button (click)="openCreate()">
    <ion-icon src="/assets/icons/color/plus.svg" class="center primary" *ngIf="edit"></ion-icon>
    <ion-icon src="/assets/icons/color/copy-01.svg" class="center primary" *ngIf="!edit && data"></ion-icon>
    <ion-spinner *ngIf="!data"></ion-spinner>
  </ion-fab-button>

  <ion-fab-list side="top">

    <!-- Tabs -->

    <ion-fab-button [title]="'Goal' | translate" (click)="openManage('goal')" *ngIf="type == 'tab'">
      <ion-icon src="/assets/icons/light/target-04.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'List' | translate" (click)="openManage('bucket')" *ngIf="type == 'tab'">
      <ion-icon src="/assets/icons/light/list.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Trip' | translate" (click)="openManage('trip')" *ngIf="type == 'tab'">
      <ion-icon src="/assets/icons/light/route.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Place' | translate" (click)="openSearch()" *ngIf="type == 'tab'">
      <ion-icon src="/assets/icons/light/globe-05.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Invite' | translate" (click)="openInvite()" *ngIf="type == 'tab'">
      <ion-icon src="/assets/icons/light/users-plus.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <!-- Copy -->

    <ion-fab-button [title]="'Goal' | translate" (click)="openManage('goal')" *ngIf="!data?.edit && type == 'goal'">
      <ion-icon src="/assets/icons/light/target-04.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'List' | translate" (click)="openManage('bucket')" *ngIf="!data?.edit && type == 'bucket'">
      <ion-icon src="/assets/icons/light/list.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Trip' | translate" (click)="openManage('trip')" *ngIf="!data?.edit && type == 'trip'">
      <ion-icon src="/assets/icons/light/route.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <!-- Views -->

    <ion-fab-button [title]="'Goal' | translate" (click)="openManage('goal')" *ngIf="data?.edit && (type == 'trip' || type == 'bucket')">
      <ion-icon src="/assets/icons/light/target-04.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Task' | translate" (click)="openManage('task')" *ngIf="data?.edit && type == 'goal'">
      <ion-icon src="/assets/icons/light/check-circle-broken.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Tickets' | translate" (click)="openTickets()" *ngIf="data?.edit && data?.place && (type == 'trip' || type == 'bucket' || type == 'goal')">
      <ion-icon src="/assets/icons/light/ticket-01.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Stays' | translate" (click)="openStays()" *ngIf="data?.edit && type == 'trip'">
      <ion-icon src="/assets/icons/light/building-07.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Flights' | translate" (click)="openFlights()" *ngIf="data?.edit && type == 'trip'">
      <ion-icon src="/assets/icons/light/plane.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Rentals' | translate" (click)="openRentals()" *ngIf="data?.edit && type == 'trip'">
      <ion-icon src="/assets/icons/light/car-01.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <ion-fab-button [title]="'Assistant' | translate" class="assistant" (click)="openAssistant()" *ngIf="data?.edit && (type == 'trip' || type == 'bucket')">
      <ion-icon src="/assets/icons/color/stars-02.svg" class="center primary"></ion-icon>
    </ion-fab-button>

    <!--
    <ion-fab-button [title]="'Tour' | translate" class="tour">
      <ion-icon src="/assets/icons/light/message-question-circle.svg" class="center primary"></ion-icon>
    </ion-fab-button>
    -->

  </ion-fab-list>

</ion-fab>