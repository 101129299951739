import { mapboxKey, mapboxLight, mapboxDark, pageMin, pageMap, pagePlace, pageStart, pageBackdrop, pageMax } from '../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SearchPage, PlacePage, ViewPage } from '../../pages';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService,
} from '..';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  // Variables
  map!: mapboxgl.Map;
  markers: any = [];
  places: any;
  placeModal: any;
  placeBreakpoint = pagePlace;
  mapModal: any;
  mapBreakpoint: number = pageStart;
  mapButtons: boolean = true;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public modalMapbox: ModalController,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Init Map
  //----------------------------------------------------------------------------

  async initMap(): Promise<mapboxgl.Map> {

    // Scheme
    let scheme = await this.capacitor.getAppearance();
    let style = window.matchMedia('(prefers-color-scheme: dark)');
    let appearance = scheme != 'system' ? scheme : style.matches ? 'dark' : 'light';
    
    // Setup
    this.map = new mapboxgl.Map({
      container: 'map',
      accessToken: mapboxKey,
      style: appearance == 'dark' ? mapboxDark : mapboxLight,
      center: [4.282741, 47.201187],
      antialias: true,
      maxZoom: 18,
      minZoom: 2,
      pitch: 0,
      zoom: 3,
    });

    // Configuration
    this.setConfiguration();
    this.setInteractions();

    // Style
    style.addListener(async (e: any) => {
      if (scheme == 'system') this.setStyle(e.matches ? 'dark' : 'light');
    });

    // Return
    return this.map;
  }

  //----------------------------------------------------------------------------
  // Set Configuration
  //----------------------------------------------------------------------------

  async setConfiguration() {
    this.map.setMaxPitch(0);
    this.map.setMinPitch(0);
    this.map.touchPitch.disable();
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.setPadding({ top: 100, right: 75, bottom: 180, left: 75 });
  }

  //----------------------------------------------------------------------------
  // Set Interactions
  //----------------------------------------------------------------------------

  async setInteractions() {
    ['dragstart', 'zoomstart'].forEach((event) => {
      this.map.on(event, () => {
        if (window.innerWidth <= 768) {
          this.mapModal.setCurrentBreakpoint(pageMap);
        }
      });
    });
  }

  //----------------------------------------------------------------------------
  // Set Style
  //----------------------------------------------------------------------------

  async setStyle(scheme: string) {
    this.map.setStyle(scheme == 'dark' ? mapboxDark : mapboxLight);
  }

  //----------------------------------------------------------------------------
  // Load Map
  //----------------------------------------------------------------------------

  async showMap(data: any) {
    if (data?.map) setTimeout(() => {
      this.places = data?.places;
      this.setCountries(data?.map?.countries);
      this.setRegions(data?.map?.regions);
      this.setCities(data?.map?.cities);
      this.setMarkers(data?.places);
      this.setLanguage();
      this.resizeMap();
    }, 200);
  }

  //----------------------------------------------------------------------------
  // Show Places
  //----------------------------------------------------------------------------

  async showPlaces(data: any) {

    // Hide
    this.mapButtons = false;
    this.map.setLayoutProperty('countries-added', 'visibility', 'none');
    this.map.setLayoutProperty('countries-completed', 'visibility', 'none');
    this.map.setLayoutProperty('countries-active', 'visibility', 'none');
    this.map.setLayoutProperty('regions-added', 'visibility', 'none');
    this.map.setLayoutProperty('regions-completed', 'visibility', 'none');
    this.map.setLayoutProperty('cities-added', 'visibility', 'none');
    this.map.setLayoutProperty('cities-completed', 'visibility', 'none');

    // Markers
    this.removeMarkers();
    this.setMarkers(data?.places);

    // Routes
    if(this.map.getLayer('routes')) this.map.removeLayer('routes');
    if(this.map.getSource('routes')) this.map.removeSource('routes');
    this.setRoutes(data?.places);

    // Bounds
    var bounds = new mapboxgl.LngLatBounds();
    data?.places?.features.forEach(function (feature: any) {
      bounds.extend(feature.geometry.coordinates);
    });
    if(bounds) this.map.fitBounds(bounds, {
      maxZoom: 6,
      linear: true
    });
  }

  //----------------------------------------------------------------------------
  // Hide Places
  //----------------------------------------------------------------------------

  async hidePlaces() {

    // Visible
    this.mapButtons = true;
    this.map.setLayoutProperty('countries-added', 'visibility', 'visible');
    this.map.setLayoutProperty('countries-completed', 'visibility', 'visible');
    this.map.setLayoutProperty('countries-active', 'visibility', 'visible');
    this.map.setLayoutProperty('regions-added', 'visibility', 'visible');
    this.map.setLayoutProperty('regions-completed', 'visibility', 'visible');
    this.map.setLayoutProperty('cities-added', 'visibility', 'visible');
    this.map.setLayoutProperty('cities-completed', 'visibility', 'visible');

    // Routes
    if(this.map.getLayer('routes')) this.map.removeLayer('routes');
    if(this.map.getSource('routes')) this.map.removeSource('routes');

    // Markers
    this.removeMarkers();
    this.setMarkers(this.places);

    // Zoom
    this.resizeMap();
    this.map.flyTo({ zoom: 4 });
  }

  //----------------------------------------------------------------------------
  // Show Geolocation
  //----------------------------------------------------------------------------

  async showGeolocation(coordinates: any) {
    if (coordinates) {
      this.map.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 8,
        speed: 2,
        curve: 1.4,
        easing: (t) => t,
      });
      const markerElement = document.createElement('div');
      markerElement.className = 'user-location-marker';
      new mapboxgl.Marker({ element: markerElement })
        .setLngLat([coordinates.longitude, coordinates.latitude])
        .addTo(this.map);
    }
  }

  //----------------------------------------------------------------------------
  // Move Map
  //----------------------------------------------------------------------------

  async moveMap(bottom: number = 0, left: number = 0) {
    this.map.easeTo({
      padding: { bottom: bottom, left: left },
      duration: 1000
    });
  }

  //----------------------------------------------------------------------------
  // Set Routes
  //----------------------------------------------------------------------------

  async setRoutes(places: any) {
    if (!this.map.getSource('routes')) {
      this.map.addSource('routes', {
        type: 'geojson',
        data: places
      });
      this.map.addLayer({
        id: 'routes',
        type: 'line',
        source: 'routes',
        filter: ['==', '$type', 'LineString'],
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#ACBF95',
          'line-width': 4
        }
      });
    }
  }

  //----------------------------------------------------------------------------
  // Set Markers
  //----------------------------------------------------------------------------

  async setMarkers(places: any) {
    places.features.forEach((marker: any) => {
      if (marker.geometry.type == 'Point') {

        // Marker Element
        var elMarker = document.createElement('div');
        if (marker.properties.image != '') {
          elMarker.className = 'image ' + marker.properties.status;
          elMarker.style.backgroundImage = 'url(' + marker.properties.image + ')';
        } else {
          elMarker.className = 'marker ' + marker.properties.status;
        }

        // Marker Click
        elMarker.addEventListener('click', (e) => {
          this.firebase.setEvent('map_marker');
          e.preventDefault();
        });

        // Marker Resize
        var el = document.getElementById('map');
        if (el) el.classList.add('marker-resize');
        this.map.on('zoom', () => {
          if (this.map.getZoom() > 5) {
            if (el) el.classList.remove('marker-resize');
          } else {
            if (el) el.classList.add('marker-resize');
          }
        });

        // Marker Popup
        const popupContent = document.createElement('div');
        popupContent.innerHTML = `<strong>${marker.properties.title}</strong>`;
        popupContent.style.cursor = 'pointer';
        popupContent.addEventListener('click', (event) => {
          this.firebase.setEvent('map_popup');
          this.ionic.openPage(ViewPage, marker.properties.title, marker.properties.id);
          event.stopPropagation();
        });
        const popup = new mapboxgl.Popup({
          offset: 25
        }).setDOMContent(popupContent);

        // Marker Add
        var newMarker = new mapboxgl.Marker(elMarker)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(popup)
          .addTo(this.map);
        this.markers.push(newMarker);

        // Background Clicks
        this.map.on('click', async (e: {
          originalEvent: {
            defaultPrevented: any; preventDefault: () => void;
          };
        }) => {
          if (!e.originalEvent.defaultPrevented) {
            e.originalEvent.preventDefault();
          }
        });
      }
    });
  }

  //----------------------------------------------------------------------------
  // Remove Markers
  //----------------------------------------------------------------------------

  async removeMarkers() {
    if (this.markers !== null) {
      for (var i = this.markers.length - 1; i >= 0; i--) {
        this.markers[i].remove();
      }
    }
  }

  //----------------------------------------------------------------------------
  // Open Country
  //----------------------------------------------------------------------------

  async openCountry(data: any, status: string) {
    this.firebase.setEvent('map_country');

    // Map
    var bbox = turf.bbox(data);
    this.map.setFilter('countries-active', ['==', 'code', data.properties.code]);
    this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]],
      { padding: {top:100, bottom:400, left:100, right:100}, maxZoom: 6 }
    );

    //  Breakpoints
    this.mapModal.setCurrentBreakpoint(pageMap);

    // Create
    this.placeModal = await this.modalMapbox.create({
      component: PlacePage,
      initialBreakpoint: pagePlace,
      breakpoints: [pageMin, pagePlace, pageMax],
      backdropBreakpoint: 1,
      showBackdrop: false,
      backdropDismiss: false,
      canDismiss: true,
      animated: true,
      handle: true,
      componentProps: {
        id: data.properties?.id,
        status: status
      }
    });

    // Listener
    this.placeModal.addEventListener('ionBreakpointDidChange', (event: any) => {
      this.placeBreakpoint = event.detail.breakpoint;
    });

    // Dismiss
    this.placeModal.onDidDismiss().then(() => {
      this.placeBreakpoint = pageMap;
    });

    // Present
    if (await this.modalMapbox.getTop()) this.modalMapbox.dismiss();
    await this.placeModal.present();
  }

  //----------------------------------------------------------------------------
  // Dismiss Country
  //----------------------------------------------------------------------------

  async dismissCountry() {
    this.map.setFilter('countries-active', ['==', 'code', '']);
    const modal = await this.modalMapbox.getTop();
    if (modal) {
      this.moveMap();
      modal.setCurrentBreakpoint(0);
      await this.ionic.setTimeout(500);
      await this.modalMapbox.dismiss();
      this.events.refreshPages();
    }
  }

  //----------------------------------------------------------------------------
  // Set Countries
  //----------------------------------------------------------------------------

  async setCountries(data: any) {

    // Added
    this.map.setFilter('countries-added', ['==', 'code', '']);
    this.map.setFilter('countries-added', data['added']);
    this.map.on('click', 'countries-added', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        await this.openCountry(e.features[0], 'added');
      }
    });

    // Completed
    this.map.setFilter('countries-completed', ['==', 'code', '']);
    this.map.setFilter('countries-completed', data['completed']);
    this.map.on('click', 'countries-completed', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        await this.openCountry(e.features[0], 'completed');
      }
    });

    // Open
    this.map.setFilter('countries-active', ['==', 'code', '']);
    this.map.on('click', 'countries-open', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        await this.openCountry(e.features[0], 'open');
      }
    });

    // Dismiss
    this.map.on('click', 'water', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        this.dismissCountry();
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Regions
  //----------------------------------------------------------------------------

  async setRegions(data: any) {
    this.map.setFilter('regions-added', data['added']);
    this.map.setFilter('regions-completed', data['completed']);
  }

  //----------------------------------------------------------------------------
  // Set Cities
  //----------------------------------------------------------------------------

  async setCities(data: any) {
    this.map.setFilter('cities-added', data['added']);
    this.map.setFilter('cities-completed', data['completed']);
  }

  //----------------------------------------------------------------------------
  // Update Scratch
  //----------------------------------------------------------------------------

  async addMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'added' });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Update Scratch
  //----------------------------------------------------------------------------

  async completeMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'completed' });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Delete Scratch
  //----------------------------------------------------------------------------

  async deleteMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.deleteRequest('/view/' + id, {});
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Add City
  //----------------------------------------------------------------------------

  async suggestMap(iso: string) {
    const data = await this.ionic.openPage(SearchPage, 'places', iso);
    if (data) await this.ionic.showAlert('Success', this.translate.instant('CityAdded'));
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Modal Height
  //----------------------------------------------------------------------------

  async modalHeight(value: number) {
    this.placeModal.setCurrentBreakpoint(value);
  }

  //----------------------------------------------------------------------------
  // Modal Place Breakpoint
  //----------------------------------------------------------------------------

  async modalPlaceBreakpoint() {
    let value = this.placeBreakpoint == pageMax ? pagePlace : pageMax;
    this.placeModal.setCurrentBreakpoint(value);
  }

  //----------------------------------------------------------------------------
  // Modal Map Breakpoint
  //----------------------------------------------------------------------------

  async modalMapBreakpoint() {
    let value = this.mapBreakpoint == pageMax ? pageMap : pageMax;
    this.mapModal.setCurrentBreakpoint(value);
  }

  //----------------------------------------------------------------------------
  // Resize Map
  //----------------------------------------------------------------------------

  async resizeMap() {
    setTimeout(() => {
      this.map.resize();
    }, 100);
  }

  //----------------------------------------------------------------------------
  // Set language
  //----------------------------------------------------------------------------

  async setLanguage() {
    const lang = await this.capacitor.getLanguage();
    this.map.setLayoutProperty('countries-labels', 'text-field', [
      'get', 'title_' + lang
    ]);
    this.map.setLayoutProperty('regions-labels', 'text-field', [
      'get', 'title_' + lang
    ]);
    this.map.setLayoutProperty('cities-labels', 'text-field', [
      'get', 'title_' + lang
    ]);
  }
}
