<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Tour' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Intro -->

  <ion-grid>
    <ion-list>
      <div class="intro">
        <div class="title">Welcome to<br>your bucket list</div>
        <div class="text">The best place to achieve your life goals, track places you’ve been and plan tour next trip. Not sure how to get started? This guide will help you setup your bucket list using iBucket.</div>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Goals -->
   
  <ion-grid *ngIf="tour && tour?.goals">
    <ion-list *ngFor="let section of tour?.goals">
      <div class="section">
        <div class="header">Goals</div>
        <!--
        <ul *ngIf="section.articles.length != 0">
          <div class="subheader primary">Getting started</div>
          <li class="primary" *ngFor="let article of section.articles">
            <ion-icon src="/assets/icons/color/{{article.icon}}.svg"></ion-icon>
            {{ article.title }}
          </li>
        </ul>
        -->
        <ul *ngIf="section.guide.length != 0">
          <div class="subheader">Guide</div>
          <li *ngFor="let guide of section.guide">
            <ion-icon src="/assets/icons/color/{{guide.icon}}.svg"></ion-icon>
            {{ guide.title }}
          </li>
        </ul>
        <ul *ngIf="section.legenda.length != 0">
          <div class="subheader">Legenda</div>
          <li *ngFor="let legenda of section.legenda">
            <ion-icon src="/assets/icons/color/{{legenda.icon}}.svg"></ion-icon>
            {{ legenda.title }}
          </li>
        </ul>
        <app-item [data]="section.example" [reorder]="true" sort="manual:asc" [options]="true"></app-item>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Lists -->
   
  <ion-grid *ngIf="tour && tour?.lists">
    <ion-list *ngFor="let section of tour.lists">
      <div class="section">
        <div class="header">Lists</div>
        <!--
        <ul *ngIf="section.articles.length != 0">
          <div class="subheader primary">Getting started</div>
          <li class="primary" *ngFor="let article of section.articles">
            <ion-icon src="/assets/icons/color/{{article.icon}}.svg"></ion-icon>
            {{ article.title }}
          </li>
        </ul>
        -->
        <ul *ngIf="section.guide.length != 0">
          <div class="subheader">Guide</div>
          <li *ngFor="let guide of section.guide">
            <ion-icon src="/assets/icons/color/{{guide.icon}}.svg"></ion-icon>
            {{ guide.title }}
          </li>
        </ul>
        <ul *ngIf="section.legenda.length != 0">
          <div class="subheader">Legenda</div>
          <li *ngFor="let legenda of section.legenda">
            <ion-icon src="/assets/icons/color/{{legenda.icon}}.svg"></ion-icon>
            {{ legenda.title }}
          </li>
        </ul>
        <app-header title="Goals" type="bucket" [edit]="true" add="true" sorting="manual:asc"></app-header>
        <app-card [data]="section.example"></app-card>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Tasks -->
   
  <ion-grid *ngIf="tour && tour?.tasks">
    <ion-list *ngFor="let section of tour.tasks">
      <div class="section">
        <div class="header">Tasks</div>
        <!--
        <ul *ngIf="section.articles.length != 0">
          <div class="subheader primary">Getting started</div>
          <li class="primary" *ngFor="let article of section.articles">
            <ion-icon src="/assets/icons/color/{{article.icon}}.svg"></ion-icon>
            {{ article.title }}
          </li>
        </ul>
        -->
        <ul *ngIf="section.guide.length != 0">
          <div class="subheader">Guide</div>
          <li *ngFor="let guide of section.guide">
            <ion-icon src="/assets/icons/color/{{guide.icon}}.svg"></ion-icon>
            {{ guide.title }}
          </li>
        </ul>
        <ul *ngIf="section.legenda.length != 0">
          <div class="subheader">Legenda</div>
          <li *ngFor="let legenda of section.legenda">
            <ion-icon src="/assets/icons/color/{{legenda.icon}}.svg"></ion-icon>
            {{ legenda.title }}
          </li>
        </ul>
        <app-item [data]="section.example" [reorder]="true" sort="manual:asc" [options]="true"></app-item>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Places -->
   
  <ion-grid *ngIf="tour && tour?.places">
    <ion-list *ngFor="let section of tour.places">
      <div class="section">
        <div class="header">Places</div>
        <!--
        <ul *ngIf="section.articles.length != 0">
          <div class="subheader primary">Getting started</div>
          <li class="primary" *ngFor="let article of section.articles">
            <ion-icon src="/assets/icons/color/{{article.icon}}.svg"></ion-icon>
            {{ article.title }}
          </li>
        </ul>
        -->
        <ul *ngIf="section.guide.length != 0">
          <div class="subheader">Guide</div>
          <li *ngFor="let guide of section.guide">
            <ion-icon src="/assets/icons/color/{{guide.icon}}.svg"></ion-icon>
            {{ guide.title }}
          </li>
        </ul>
        <ul *ngIf="section.legenda.length != 0">
          <div class="subheader">Legenda</div>
          <li *ngFor="let legenda of section.legenda">
            <ion-icon src="/assets/icons/color/{{legenda.icon}}.svg"></ion-icon>
            {{ legenda.title }}
          </li>
        </ul>
        <app-item [data]="section.example"></app-item>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Friends -->
   
  <ion-grid *ngIf="tour && tour?.friends">
    <ion-list *ngFor="let section of tour.friends">
      <div class="section">
        <div class="header">Friends</div>
        <!--
        <ul *ngIf="section.articles.length != 0">
          <div class="subheader primary">Getting started</div>
          <li class="primary" *ngFor="let article of section.articles">
            <ion-icon src="/assets/icons/color/{{article.icon}}.svg"></ion-icon>
            {{ article.title }}
          </li>
        </ul>
        -->
        <ul *ngIf="section.guide.length != 0">
          <div class="subheader">Guide</div>
          <li *ngFor="let guide of section.guide">
            <ion-icon src="/assets/icons/color/{{guide.icon}}.svg"></ion-icon>
            {{ guide.title }}
          </li>
        </ul>
        <ul *ngIf="section.legenda.length != 0">
          <div class="subheader">Legenda</div>
          <li *ngFor="let legenda of section.legenda">
            <ion-icon src="/assets/icons/color/{{legenda.icon}}.svg"></ion-icon>
            {{ legenda.title }}
          </li>
        </ul>
        <app-item [data]="section.example"></app-item>
      </div>
    </ion-list>
  </ion-grid>

</ion-content>