<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Share' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding footer">
  x
</ion-content>