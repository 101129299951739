<ion-content class="ion-padding">

  <button ion-button (click)="closePage()" class="close">
    <ion-icon [src]="'/assets/icons/dark/x.svg'"></ion-icon>
  </button>

  <!-- Header -->

  <ion-grid class="header">
    <img src="/assets/svg/premium-logo.svg" alt="iBucket Premium" width="180" height="auto"/>
    <div class="title">{{ 'PremiumTitle' | translate }}</div>
    <img src="/assets/svg/premium-header.svg" alt="World" width="320" height="auto"/><br>
    <button id="open-reviews" expand="block" class="button" (click)="openReviews()">
      <div class="stars">
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        <ion-icon src="/assets/icons/color/star-01.svg"></ion-icon>
        4.8
      </div>
      {{ 'PremiumReviews' | translate }}
    </button>
  </ion-grid>

  <ion-grid class="content small">
    <ion-row>

      <!-- Select -->
      
      <div class="select">{{ 'PremiumSelect' | translate }}</div>
      <ion-skeleton-text class="premium" [animated]="true" *ngIf="!loaded"></ion-skeleton-text>
      <ion-skeleton-text class="premium" [animated]="true" *ngIf="!loaded"></ion-skeleton-text>
      <ion-skeleton-text class="premium" [animated]="true" *ngIf="!loaded"></ion-skeleton-text>

      <!-- Packages -->

      <ion-col size="12" *ngFor="let package of packages">
        <ion-card [class.active]="purchase.active == package?.refer && !package?.locked" (click)="purchase.selectPackage(package)" [disabled]="package?.locked || spinner" *ngIf="package?.refer != 'assistant'">
          <div class="top">

            <ion-icon src="/assets/icons/dark/circle.svg" class="select" *ngIf="!package?.locked"></ion-icon>
            <ion-icon src="/assets/icons/color/check-circle-solid.svg" class="select selected" *ngIf="!package?.locked"></ion-icon>
            <ion-icon src="/assets/icons/color/lock-02.svg" class="select locked" *ngIf="package?.locked"></ion-icon>

            <div class="package">
              {{ package?.title | translate }}
            </div>
            <div class="pricing" *ngIf="!package?.locked">
              <div class="price" *ngIf="!package?.purchased">{{ package?.price }}</div>
              <div class="type" *ngIf="!package?.purchased">{{ 'PremiumOnce' | translate }}</div>
              <div class="banner discount" *ngIf="package?.discount && !package?.purchased && !package?.locked">{{ 'PremiumSave' | translate: { discount: package?.discount } }}</div>
              <div class="banner purchased" *ngIf="package?.purchased"><ion-icon src="/assets/icons/color/check-circle-solid.svg"></ion-icon> {{ 'PremiumOwned' | translate }}</div>
            </div>
            <div class="subtitle" *ngIf="package?.locked">
              {{ 'PremiumLocked' | translate }}
            </div>
          </div>

          <ul class="features" *ngIf="!package?.locked">
            <li class="feature" *ngFor="let feature of package?.features">
              <ion-icon src="/assets/icons/light/check-circle-broken.svg" *ngIf="purchase.active == package?.refer"></ion-icon>
              <ion-icon src="/assets/icons/dark/check-circle-broken.svg" *ngIf="purchase.active != package?.refer"></ion-icon>
              {{ feature.title | translate }}
            </li>
          </ul>

        </ion-card>
      </ion-col>

      <!-- Buttons -->
  
      <ion-col size="12">
        <ion-grid class="buttons">
          <a id="open-reviews" expand="block" class="button" (click)="openReviews()">{{ 'PremiumReviews' | translate }}</a>
          <a class="button restore" (click)="purchase.restorePackage();spinner = true;" *ngIf="capacitor.isNative">{{ 'Restore' | translate }}</a>
        </ion-grid>
      </ion-col>

    </ion-row>
  </ion-grid>

  <!-- Purchase -->
  
  <ion-footer slot="fixed" class="primary">
    <button ion-button class="cta-button dark" *ngIf="!loaded"></button>
    <div *ngFor="let package of packages">
      <button ion-button class="cta-button premium" (click)="purchase.purchasePackage(package);spinner = true;" [class.dark]="package?.purchased" [class.full]="!spinner" [disabled]="package?.purchased" *ngIf="purchase.active == package?.refer && !package?.locked">
        <div class="purchase" *ngIf="!package?.purchased">
          <div class="info">
            <div class="purchase">{{ 'PremiumBuy' | translate }}</div><br>
            <div class="package">{{ package?.title | translate }}</div>
          </div>
          <div class="price">
            <span>{{ package?.price }}</span>
          </div>
        </div>
        <div class="purchased" *ngIf="package?.purchased">
          <div class="info centered">
            <div class="purchase">{{ 'PremiumOwned' | translate }}</div><br>
            <div class="package">{{ package?.title | translate }}</div>
          </div>
        </div>
      </button>
    </div>
  </ion-footer>

</ion-content>