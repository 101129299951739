import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SearchPage } from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  IonicService,
  EventsService,
  HttpService
} from '..';

@Injectable({
  providedIn: 'root'
})
export class FriendsService {

  manage: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public modalFriends: ModalController,
    public events: EventsService,
    public ionic: IonicService,
    public platform: Platform,
    public http: HttpService,
    public router: Router,
  ) { }

  //----------------------------------------------------------------------------
  // Enable Invite
  //----------------------------------------------------------------------------

  async toggleInvite(data:any, enable: boolean) {
    this.http.isLoading(data?.id);

    // Request
    if(enable) {
      return await this.http.postRequest('/view/invite', {
        id: data?.id
      }).then(data => {
        this.events.refreshPages();
        this.http.isLoading('');
        return data?.id;
      });
    } else {
      return await this.http.deleteRequest('/view/' + data?.token)
      .then(data => {
        this.events.refreshPages();
        this.http.isLoading('');
        return false;
      });
    }

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Create Invite
  //----------------------------------------------------------------------------

  async createInvite(id: string, user: string, name: string, title: string) {
    this.firebase.setEvent('invite_create');

    // Request
    this.http.isLoading(id);
    await this.http.postRequest('/view/invite', {
      id: id,
      user: user ? user : null,
      name: name,
      title: title
    }).then(data => {
      if (data) {
        this.firebase.setEvent('invite_created');
        this.ionic.showToast(data?.message);
      }
    });
    this.events.refreshPages();
  }
  
  //----------------------------------------------------------------------------
  // User Invite
  //----------------------------------------------------------------------------

  async userInvite(id: string, title: string) {

    // Search
    this.ionic.openPage(SearchPage, 'invites').then(async json => {
      if (json?.id) {
        this.ionic.showConfirm(
          this.translate.instant('Invite'),
          this.translate.instant('InviteConfirm', { name: json?.title })
        ).then(async response => {
          if (response) await this.createInvite(id, json?.id, json?.title, title);
        });
      }
    });
  }

  //----------------------------------------------------------------------------
  // Share Invite
  //----------------------------------------------------------------------------

  async shareInvite(url: string, title: string, user: string, event?: Event) {
    this.firebase.setEvent('invite_share');
    this.events.cancelEvent(event);

    // Share
    const text = this.translate.instant('InviteShare', { user: user, title: title });
    if (await this.capacitor.isNative()) {
      await this.capacitor.openShare(url, text);
    } else {
      await this.capacitor.setClipboard(url, text);
      await this.ionic.showAlert(
        this.translate.instant('Share'),
        this.translate.instant('Copied')
      );
    }
  }

  //----------------------------------------------------------------------------
  // Accept Invite
  //----------------------------------------------------------------------------

  async acceptInvite(id: string, token: string) {

    // Request
    this.http.isLoading(id);
    if (!await this.capacitor.checkAuthorization()) {

      // Request
      await this.http.postRequest('/account', {
        language: this.capacitor.language,
        platform: this.capacitor.platform,
        push: this.capacitor.push
  
      }).then(async data => {
        if (data && data['token']) {
          await this.capacitor.setAuthorization(data['token']);
          this.router.navigateByUrl('/');
        }
        return false;
      });

      // Wait
      await this.ionic.setTimeout(500);
    }
    await this.http.putRequest('/view/' + token);
    this.events.refreshPages();
    this.events.refreshPages();

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Decline Invite
  //----------------------------------------------------------------------------

  async declineInvite(id: string, token: string) {

    // Request
    this.http.isLoading(id);
    let modal = await this.ionic.showConfirm('Delete', 'DeleteConfirm');
    if (modal) await this.http.deleteRequest('/view/' + token);
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
    this.events.refreshPages();

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Delete Item
  //----------------------------------------------------------------------------

  async deleteInvite(id: string, parent: string) {

    // Confirm
    const remove = await this.ionic.showConfirm(
      this.translate.instant('Delete'),
      this.translate.instant('DeleteConfirm')
    );
    if (!remove) return;

    // Request
    this.http.isRemoving(parent);
    await this.http.deleteRequest('/view/' + id).then(data => {
      if (data) {
        this.capacitor.removeStorage('/view/' + id);
        this.events.refreshPages();
        this.events.refreshPages();
      }
    });
  }

  //----------------------------------------------------------------------------
  // Add Friend
  //----------------------------------------------------------------------------

  async addFriend(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id);
    await this.ionic.setTimeout(500);
    this.events.refreshPages();

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Remove Friend
  //----------------------------------------------------------------------------

  async removeFriend(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.deleteRequest('/view/' + id);
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Approve Friend
  //----------------------------------------------------------------------------

  async approveFriend(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'approved' });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Deny Friend
  //----------------------------------------------------------------------------

  async denyFriend(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'denied' });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Like Feed
  //----------------------------------------------------------------------------

  async likeFeed(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.postRequest('/view/like', { id: id });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();

    // Notifications
    this.capacitor.registerNotifications();
  }

  //----------------------------------------------------------------------------
  // Unlike Feed
  //----------------------------------------------------------------------------

  async unlikeFeed(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.deleteRequest('/view/like', { id: id });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Login Accept
  //----------------------------------------------------------------------------

  async loginAccept(token: string) {
    this.firebase.setEvent('app_login');

    // Request
    await this.http.putRequest('/login', {
      token: token,
      platform: this.capacitor.platform,
      push: this.capacitor.push

    }).then(async data => {
      if (data && data?.token) {
        await this.capacitor.setAuthorization(data?.token);
        this.events.refreshPages();
        this.router.navigateByUrl('/');
      }
    });
  }
}
