<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" [icon]="'/assets/icons/' + capacitor.scheme + '/' + 'arrow-left.svg'" defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'Settings' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  
  Media + Icon

</ion-content>