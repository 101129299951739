import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  CapacitorService,
  FirebaseService,
  FriendsService,
  IonicService
} from './services';
import { ViewPage } from './pages';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  imports: [ IonicModule, CommonModule, TranslateModule ]
})

export class AppComponent {

  public environmentInjector = inject(EnvironmentInjector);
  
  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public friends: FriendsService,
    public ionic: IonicService,
    public platform: Platform,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.platform.ready().then(async () => {
      if (await this.capacitor.isNative()) {
        SplashScreen.hide()
        StatusBar.setOverlaysWebView({ overlay: true });
      }
    });
  }

  ngAfterViewInit() {
    this.capacitor.initApplication();
    this.capacitor.initAppearance();
    this.capacitor.getLanguage();
    this.capacitor.initNotifications();
    this.firebase.initFirebase();
    this.openDeeplink();
  }

  //----------------------------------------------------------------------------
  // Open Deeplink
  //----------------------------------------------------------------------------

  async openDeeplink() {
    if (await this.capacitor.isNative()) {
      App.addListener('appUrlOpen', (data: any) => {
        let url = data.url.split('/');
        this.firebase.setEvent('deeplink_' + url[2]);
        switch (url[2]) {
          case 'login': this.friends.loginAccept(url[3]); break;
          case 'invite': this.friends.acceptInvite('', url[3]); break;
          case 'view': this.ionic.openPage(ViewPage, url[2], url[3]); break;
          default: break;
        }
      });
    }
  }
}
