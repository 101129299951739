<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeView()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="loaded">
      <span *ngIf="type == 'goal'">{{ 'Goal' | translate }}</span>
      <span *ngIf="type == 'bucket'">{{ 'List' | translate }}</span>
      <span *ngIf="type == 'trip'">{{ 'Trip' | translate }}</span>
      <span *ngIf="type == 'task'">{{ 'Task' | translate }}</span>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button class="component primary label" [disabled]="!loaded" [class.spinner]="disable" (click)="saveItem()">
        <ion-icon src="/assets/icons/color/save-01.svg"></ion-icon>
        <ion-label *ngIf="id">{{ 'Save' | translate }}</ion-label>
        <ion-label *ngIf="!id">{{ 'Add' | translate }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Title -->

  <ion-grid *ngIf="loaded">
    <ion-list class="padding">
      <ion-textarea mode="ios" class="title" autocapitalize="sentences" #input [(ngModel)]="view.title" [ngModelOptions]="{standalone: true}" class="title" autoGrow="true" rows="1" placeholder="{{ 'Title' | translate }}" (click)="firebase.setEvent('view_title')"></ion-textarea>
      <ion-textarea mode="ios" class="text" autocapitalize="sentences" autoGrow="1" [(ngModel)]="view.story" [ngModelOptions]="{standalone: true}" placeholder="{{ 'Story' | translate }}" rows="6" (click)="firebase.setEvent('view_story')" *ngIf="type != 'task'"></ion-textarea>
      <ion-textarea mode="ios" class="text" autocapitalize="sentences" autoGrow="1" [(ngModel)]="view.note" [ngModelOptions]="{standalone: true}" placeholder="{{ 'Note' | translate }}" rows="6" (click)="firebase.setEvent('view_note')" *ngIf="type == 'task'"></ion-textarea>
    </ion-list>
  </ion-grid>

  <!-- Images -->

  <ion-grid *ngIf="loaded">
    <app-header title="Images"></app-header>
    <ion-list class="images">
      <ion-row>
        <ion-col size="4">
          <div class="image" (click)="setImage('1')" [style.background-image]="view?.images?.image1 ? 'url(' + view?.images?.image1 + ')' : ''">
            <div class="upload" *ngIf="upload == '1'">
              <ion-spinner></ion-spinner>
            </div>
            <ion-icon class="img" src="/assets/icons/color/image-03.svg" [hidden]="upload == '1'" *ngIf="!view?.images?.image1"></ion-icon>
            <div class="delete" (click)="deleteImage('1');$event.stopPropagation();" *ngIf="view?.images?.image1">
              <ion-icon class="close" src="/assets/icons/color/x.svg"></ion-icon>
            </div>
          </div>
        </ion-col>
        <ion-col size="4">
          <div class="image" (click)="setImage('2')" [style.background-image]="view?.images?.image2 ? 'url(' + view?.images?.image2 + ')' : ''">
            <div class="upload" *ngIf="upload == '2'">
              <ion-spinner></ion-spinner>
            </div>
            <ion-icon class="img" src="/assets/icons/color/image-03.svg" [hidden]="upload == '2'" *ngIf="!view?.images?.image2"></ion-icon>
            <div class="delete" (click)="deleteImage('2');$event.stopPropagation();" *ngIf="view?.images?.image2">
              <ion-icon class="close" src="/assets/icons/color/x.svg"></ion-icon>
            </div>
          </div>
        </ion-col>
        <ion-col size="4">
          <div class="image" (click)="setImage('3')" [style.background-image]="view?.images?.image3 ? 'url(' + view?.images?.image3 + ')' : ''">
            <div class="upload" *ngIf="upload == '3'">
              <ion-spinner></ion-spinner>
            </div>
            <ion-icon class="img" src="/assets/icons/color/image-03.svg" [hidden]="upload == '3'" *ngIf="!view?.images?.image3"></ion-icon>
            <div class="delete" (click)="deleteImage('3');$event.stopPropagation();" *ngIf="view?.images?.image3">
              <ion-icon class="close" src="/assets/icons/color/x.svg"></ion-icon>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-list>
  </ion-grid>

  <!-- Manage -->

  <ion-grid *ngIf="loaded">
    <app-header title="Manage"></app-header>
    <ion-list class="group">
      <app-item type="manage" class="warning" label="AssistantAI" icon="stars-02.svg" (click)="openAssistant()" *ngIf="type == 'trip' || type == 'bucket' || type == 'goal'"></app-item>
      <app-item type="manage" class="primary" label="Invite" icon="user-plus-01.svg" (click)="openInvite()" *ngIf="(type == 'trip' || type == 'bucket') && view?.owner"></app-item>
      <app-item type="manage" label="Completed" icon="check-circle.svg" [toggle]="true" [data]="view?.status" (click)="setStatus()" *ngIf="type == 'goal' || type == 'task'"></app-item>
      <app-item type="manage" label="Autocomplete" icon="clock-check.svg" [toggle]="true" [data]="view?.autocomplete" (click)="setAutocomplete()" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [label]="view?.activity ? view?.activityTitle : 'Activity'" [sublabel]="view?.activity ? 'Activity' : ''" [data]="view?.activity" value="activity" icon="hearts.svg" (click)="setActivity()" *ngIf="type == 'goal'"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Planning -->

  <ion-grid *ngIf="loaded && type != 'bucket'">
    <app-header title="Planning"></app-header>
    <ion-list class="group">
      <app-item type="manage" [date]="view?.completed ? true : false" [label]="view?.completed ? view?.completed : 'Completed'" [sublabel]="view?.completed ? 'Completed' : ''" icon="calendar-check-01.svg" [data]="view?.completed" value="completed" (click)="openDate('completed')" *ngIf="type == 'goal' || type == 'task'"></app-item>
      <app-item type="manage" [date]="view?.planned ? true : false" [label]="view?.planned ? view?.planned : 'Planned'" [sublabel]="view?.planned ? 'Planned' : ''" icon="calendar-date.svg" [data]="view?.planned" value="planned" (click)="openDate('planned')" *ngIf="type == 'goal' || type == 'task'"></app-item>
      <app-item type="manage" [date]="view?.finished ? true : false" [label]="view?.finished ? view?.finished : 'Finished'" [sublabel]="view?.finished ? 'Finished' : ''" icon="calendar-check-01.svg" [data]="view?.finished" value="finished" (click)="openDate('finished')" *ngIf="type == 'goal' || type == 'task'"></app-item>
      <app-item type="manage" [date]="view?.startDate ? true : false" [label]="view?.startDate ? view?.startDate : 'DateStart'" [sublabel]="view?.startDate ? 'DateStart' : ''" icon="calendar-plus-01.svg" [data]="view?.startDate" value="startDate" (click)="openDate('startDate')" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [date]="view?.endDate ? true : false" [label]="view?.endDate ? view?.endDate : 'DateEnd'" [sublabel]="view?.endDate ? 'DateEnd' : ''" icon="calendar-check-01.svg" [data]="view?.endDate" value="endDate" (click)="openDate('endDate')" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [date]="view?.reminder ? true : false" [label]="view?.reminder ? view?.reminder : 'Reminder'" [sublabel]="view?.reminder ? 'Reminder' : ''" icon="bell-ringing-03.svg" [data]="view?.reminder" value="reminder" (click)="openDate('reminder')" *ngIf="type == 'goal' || type == 'task'"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Organize -->

  <ion-grid *ngIf="loaded">
    <app-header title="Organize"></app-header>
    <ion-list class="group">
      <app-item type="manage" [label]="view?.place ? view?.placeTitle : 'Place'" [sublabel]="view?.place ? 'Place' : ''" [data]="view?.place" value="place" icon="marker-pin-01.svg" (click)="setPlace('place')" *ngIf="type != 'trip' && type != 'bucket'"></app-item>
      <app-item type="manage" [label]="view?.startPlace ? view?.startTitle : 'PlaceStart'" [sublabel]="view?.startPlace ? 'PlaceStart' : ''" [data]="view?.startPlace" value="startPlace" icon="marker-pin-06.svg" (click)="setPlace('placestart')" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [label]="view?.endPlace ? view?.endTitle : 'PlaceEnd'" [sublabel]="view?.endPlace ? 'PlaceEnd' : ''" [data]="view?.endPlace" value="endPlace" icon="marker-pin-05.svg" (click)="setPlace('placeend')" *ngIf="type == 'trip'"></app-item>
      <app-item type="manage" [label]="view?.bucket ? view?.bucketTitle : 'List'" [sublabel]="view?.bucket ? 'List' : ''" [data]="view?.bucket" value="bucket" icon="list.svg" (click)="setLists()" *ngIf="type == 'goal' && view?.owner"></app-item>
      <app-item type="manage" [label]="view?.trip ? view?.tripTitle : 'Trip'" [sublabel]="view?.trip ? 'Trip' : ''" [data]="view?.trip" value="trip" icon="route.svg" (click)="setLists()" *ngIf="type == 'goal' && view?.owner"></app-item>
      <app-item type="manage" [label]="view?.category ? view?.categoryTitle : 'Category'" [sublabel]="view?.category ? 'Category' : ''" [data]="view?.category" value="category" icon="folder.svg" (click)="setCategory()" *ngIf="type != 'trip' && type != 'task'"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Notes -->

  <ion-grid *ngIf="loaded && type != 'bucket'">
    <app-header class="nomargin" title="Notes"></app-header>
    <ion-list class="group">
      <app-item type="manage" label="Tickets" icon="check-circle.svg" [toggle]="true" [data]="view?.tickets" (click)="setTickets()" *ngIf="type == 'goal'"></app-item>
      <app-item type="manage" [label]="view?.reservation ? view?.reservation : 'Reservation'" [sublabel]="view?.reservation ? 'Reservation' : ''" [data]="view?.reservation" value="reservation" icon="file-05.svg" (click)="setReservation()" *ngIf="type == 'goal'"></app-item>
      <app-item type="manage" [label]="view?.budget ? view?.budget : 'Budget'" [sublabel]="view?.budget ? 'Budget' : ''" [data]="view?.budget" value="budget" icon="wallet-02.svg" (click)="setBudget()" *ngIf="type == 'goal' || type == 'trip'"></app-item>
      <app-item type="manage" [label]="view?.website ? view?.website : 'Website'" [sublabel]="view?.website ? 'Website' : ''" [data]="view?.website" value="website" icon="link-03.svg" (click)="setWebsite()" *ngIf="type == 'goal'"></app-item>
      <app-item type="manage" [label]="view?.video ? view?.video : 'Video'" [sublabel]="view?.video ? 'Video' : ''" [data]="view?.video" value="video" icon="video-recorder.svg" (click)="setVideo()" *ngIf="type == 'goal'"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Delete -->

  <ion-grid *ngIf="loaded">
    <ion-list class="group">
      <app-item type="manage" label="Delete" icon="trash-01.svg" (click)="deleteItem()" *ngIf="id && view?.owner"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Goals -->

  <ion-grid *ngIf="loaded && view?.goals.length != 0">
    <ion-list class="group">
    <app-header class="reorder" title="GoalsAdded"></app-header>
    <ion-item class="single goal" *ngFor="let goal of view?.goals; index as i; trackBy: ionic.trackItem" [class.hide]="!goal?.title">
      <ion-icon src="/assets/icons/color/plus-circle.svg"></ion-icon>
      <ion-label class="oneliner">
        <span>{{ goal?.title }}</span>
      </ion-label>
      <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="deleteGoal(i)"></ion-icon>
    </ion-item>
  </ion-list>
  </ion-grid>

  <!-- DateTime -->

  <ion-modal #datemodal class="datetime" [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime #datetime mode="ios" presentation="date-time" size="cover" [firstDayOfWeek]="1" [(ngModel)]="dateSelected" (ionChange)="setDate($event)">
        <ion-buttons slot="buttons">
          <ion-button color="danger" (click)="datetime.reset()">{{ 'Clear' | translate }}</ion-button>
          <ion-button color="tertiary" (click)="datetime.cancel(true)">{{ 'Cancel' | translate }}</ion-button>
          <ion-button color="primary" (click)="datetime.confirm(true)">{{ 'Save' | translate }}</ion-button>
        </ion-buttons>
      </ion-datetime>
    </ng-template>
  </ion-modal>
  
</ion-content>