import { pageMap, pageMax } from '../../../environments/environment';
import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IonicModule, InfiniteScrollCustomEvent, IonContent, IonPopover } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { PremiumPage } from '..';
import { Router } from '@angular/router';
import {
  AccountPage,
  InvitePage,
  ManagePage,
  PassportPage,
  SearchPage,
  SharePage,
  TourPage
} from '..';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  CacheService,
  HttpService,
  MapboxService
} from '../../services';

@Component({
  selector: 'modal-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, ComponentsModule, TranslateModule
  ],
})
export class HomePage implements OnInit {

  @ViewChild(IonContent, { static: false }) content!: IonContent;
  @ViewChild('switcher', { static: true }) popover!: IonPopover;
  @ViewChild('searchbar') searchBar: any;

  public pageMap = pageMap;
  public pageMax = pageMax;

  data: any;
  list: any = [];
  items: any = [];
  type: string = 'goals';
  title: string = 'My' + this.type;
  avatar: string = '';
  sync: any;
  results: any;
  count: number = 0;
  hide: boolean = false;
  expanded: boolean = false;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public events: EventsService,
    public mapbox: MapboxService,
    public ionic: IonicService,
    public cache: CacheService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone,
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.title = 'My' + this.type[0].toUpperCase() + this.type.slice(1);
    this.firebase.setScreen(this.type[0].toUpperCase() + this.type.slice(1));
    this.events.subscribeEvent('home', (sort) => { this.getData(); });
    this.checkMaximize();
    this.getCache();
  }

  ngOnDestroy() {
    this.events.destroyEvent('home');
    //this.sync.unsubscribe();
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getCache() {
    this.zone.run(async () => {
      this.data = await this.cache.getCache(this.type);
      this.infiniteScroll();
      this.getData();
    });
  }

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/me/' + this.type, this.type);
      this.avatar = this.data?.avatar;
      this.infiniteScroll();
      /* Sync
      this.sync = interval(appSync).pipe(mergeMap(async () =>
        await this.http.getRequest('/me/goals', 'goals')
      )).subscribe(data => {
        this.data = data;
      });
      */
    });
  }

  //----------------------------------------------------------------------------
  // Open Popover
  //----------------------------------------------------------------------------

  async openPopover(ev: any) {
    this.firebase.setEvent('home_switch');
    this.popover.event = await ev;
    await this.popover.present();
  }

  //----------------------------------------------------------------------------
  // Switch Content
  //----------------------------------------------------------------------------

  async switchContent(value: string) {
    this.firebase.setEvent('home_' + value);
    this.title = 'My' + value[0].toUpperCase() + value.slice(1);
    this.type = value;
    this.count = 0;
    this.data = [];
    await this.popover.dismiss();
    await this.getCache();
  }

  //----------------------------------------------------------------------------
  // Open Pages
  //----------------------------------------------------------------------------

  async openPremium() {
    this.firebase.setEvent('home_premium');
    this.ionic.openPage(PremiumPage);
  }

  async openAccount() {
    this.firebase.setEvent('home_account');
    this.ionic.openPage(AccountPage);
  }

  async openShare() {
    this.firebase.setEvent('home_share');
    this.ionic.openPage(SharePage);
  }

  async openNotifications() {
    this.firebase.setEvent('home_notifications');
    this.ionic.openPage(InvitePage);
  }

  async openTour() {
    this.firebase.setEvent('home_tour');
    this.ionic.openPage(TourPage);
  }

  async openInvite() {
    this.firebase.setEvent('home_invite');
    this.ionic.openPage(InvitePage);
  }

  async openPassport() {
    this.firebase.setEvent('home_passport');
    this.ionic.openPage(PassportPage);
  }

  async openManage(type: string) {
    this.firebase.setEvent('home_create');
    this.ionic.openPage(ManagePage, type);
  }

  async openSearch() {
    this.firebase.setEvent('home_search');
    this.ionic.openPage(SearchPage, 'map');
  }

  //----------------------------------------------------------------------------
  // Expand Views
  //----------------------------------------------------------------------------

  async expandView() {
    this.firebase.setEvent('home_expand');
    this.mapbox.modalMapBreakpoint();
  }

  async setMaximize() {
    this.firebase.setEvent('home_wide');
    if (this.expanded) {
      this.expanded = false;
      document.body.classList.remove('wide-modal');
      this.capacitor.removeStorage('Maximize');
    } else {
      this.expanded = true;
      document.body.classList.add('wide-modal');
      this.capacitor.setStorage('Maximize', 'true');
    }
  }

  async checkMaximize() {
    if (await this.capacitor.getStorage('Maximize')) {
      this.expanded = true;
      document.body.classList.add('wide-modal');
    }
  }

  //----------------------------------------------------------------------------
  // Infinite Scroll
  //----------------------------------------------------------------------------

  async reloadView() {
    this.content.scrollToTop(500);
    this.getData();
  }

  private infiniteScroll() {
    this.zone.run(async () => {
      this.count = 0;
      this.items[this.type] = [];
      if (this.data[this.type] && this.data[this.type].length >= 50) {
        for (this.count = 0; this.count < 20; this.count++) {
          this.items[this.type].push(this.data[this.type][this.count]);
        }
      } else {
        this.items[this.type] = this.data[this.type];
      }
      this.ref.detectChanges();
    });
  }

  async doInfinite(ev: Event) {
    let extra = this.count + 10;
    if (this.data[this.type].length >= extra) {
      for (this.count = this.count; this.count < extra; this.count++) {
        this.items[this.type].push(this.data[this.type][this.count]);
      }
    } else {
      (ev as InfiniteScrollCustomEvent).target.disabled = true;
    }
    (ev as InfiniteScrollCustomEvent).target.complete()
  }
}
