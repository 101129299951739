import { pageMap, pageMax, pageBackdrop } from '../../../environments/environment';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { IonicModule, ModalController, IonContent } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { Router } from '@angular/router';
import { HomePage, PassportPage, SearchPage } from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  MapboxService,
  IonicService,
  CacheService,
  HttpService,
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, ComponentsModule, TranslateModule
  ],
})
export class MapPage implements OnInit {

  @ViewChild(IonContent, { static: false }) content!: IonContent;

  map!: any;
  data: any;
  modal: any;
  tour: boolean = false;
  tourStep: number = 1;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public modalMap: ModalController,
    public mapbox: MapboxService,
    public events: EventsService,
    public ionic: IonicService,
    public cache: CacheService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initalize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.firebase.setScreen('Map');
    this.events.subscribeEvent('map', () => { this.getData(); });
    await this.mapbox.initMap();
    this.getCache();
  }

  //----------------------------------------------------------------------------
  // Get Cache
  //----------------------------------------------------------------------------

  async getCache() {
    this.zone.run(async () => {
      setTimeout(async () => {
        this.data = await this.cache.getCache('map');
        await this.mapbox.showMap(this.data);
        await this.initModal();
        this.getData();
      }, 100);
    });
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/me/map', 'map');
      await this.mapbox.resizeMap();
      await this.mapbox.showMap(this.data);
      this.openTour();
    });
  }

  //----------------------------------------------------------------------------
  // Init Modal
  //----------------------------------------------------------------------------

  async initModal() {

    // Screen
    const query = window.matchMedia('(min-width: 768px)');
    const sidebar = query.matches;
    this.mapbox.mapBreakpoint = sidebar ? 1 : this.mapbox.mapBreakpoint;

    // Modal
    this.mapbox.mapModal = await this.modalMap.create({
      component: HomePage,
      initialBreakpoint: this.mapbox.mapBreakpoint,
      breakpoints: [pageMap, pageMax],
      backdropBreakpoint: sidebar ? 1 : pageBackdrop,
      backdropDismiss: false,
      showBackdrop: sidebar ? false : true,
      canDismiss: false,
      animated: true,
      handle: true,
      cssClass: 'map'
    });

    // Map
    await this.mapbox.resizeMap();
    if (sidebar) this.mapbox.moveMap(0, 500);

    // Listener
    this.mapbox.mapModal.addEventListener('ionBreakpointDidChange', (event: any) => {
      this.mapbox.mapBreakpoint = event.detail.breakpoint;
    });

    // Append
    const modalContent: any = document.querySelector('#modal');
    modalContent.appendChild(this.mapbox.mapModal);
    await this.mapbox.mapModal.present();

    // Buttons
    var el = document.getElementById('buttons');
    if (el) el.classList.add('show');
  }

  //----------------------------------------------------------------------------
  // Open Search
  //----------------------------------------------------------------------------

  async openSearch() {
    this.firebase.setEvent('map_search');
    this.ionic.openPage(SearchPage);
  }

  //----------------------------------------------------------------------------
  // Open Passport
  //----------------------------------------------------------------------------

  async openPassport() {
    this.firebase.setEvent('map_passport');
    this.ionic.openPage(PassportPage);
  }

  //----------------------------------------------------------------------------
  // Open Filter
  //----------------------------------------------------------------------------

  async openFilter() {
    //
  }

  //----------------------------------------------------------------------------
  // Open Tour
  //----------------------------------------------------------------------------

  async openTour() {
    this.tour = !await this.capacitor.getStorage('Tour');
    if(this.tour) this.firebase.setEvent('map_tour_start');
  }

  //----------------------------------------------------------------------------
  // Next Tour
  //----------------------------------------------------------------------------

  async nextTour() {
    if (this.tourStep == 3) {
      this.firebase.setEvent('map_tour_finish');
      this.capacitor.setStorage('Tour', 'true');
      this.tour = false;
    } else {
      this.firebase.setEvent('map_tour_next');
      this.tourStep = this.tourStep == 3 ? 1 : this.tourStep + 1;
    }
  }

  //----------------------------------------------------------------------------
  // Show Geolocation
  //----------------------------------------------------------------------------

  async showGeolocation() {
    this.firebase.setEvent('map_geolocation');
    let location = await this.capacitor.requestGeolocation();
    if (location) this.mapbox.showGeolocation(location);
  }
}
