<ion-content>

  <!-- Tour -->

  <div class="tour step{{ tourStep }}" (click)="nextTour()" *ngIf="tour">
    <div class="popup first top-left" *ngIf="tourStep == 1">
      <div class="title">{{ 'TourFirstTitle' | translate }}</div>
      <div class="text">{{ 'TourFirstText' | translate }}</div>
      <div class="next">
        <div class="button">{{ 'TourNext' | translate }}</div>
        <div class="step">1/3</div>
      </div>
    </div>
    <div class="popup second top-center" *ngIf="tourStep == 2">
      <div class="title">{{ 'TourSecondTitle' | translate }}</div>
      <div class="text">{{ 'TourSecondText' | translate }}</div>
      <div class="next">
        <div class="button">{{ 'TourNext' | translate }}</div>
        <div class="step">2/3</div>
      </div>
    </div>
    <div class="popup third right-bottom" *ngIf="tourStep == 3">
      <div class="title">{{ 'TourThirdTitle' | translate }}</div>
      <div class="text">{{ 'TourThirdText' | translate }}</div>
      <div class="next">
        <div class="button">{{ 'TourStart' | translate }}</div>
        <div class="step">3/3</div>
      </div>
    </div>
  </div>

  <!-- Create -->

  <app-create type="tab" [edit]="true"></app-create>

  <!-- Modal -->

  <div id="modal"></div>

  <!-- Map -->

  <div id="map"></div>

  <!-- Buttons -->

  <div id="buttons" [class.hide]="!mapbox.mapButtons">
    <ion-button (click)="openSearch()">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/search-refraction.svg'"></ion-icon>
    </ion-button>
    <ion-button (click)="openPassport()">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/passport-02.svg'"></ion-icon>
    </ion-button>
    <!--
    <ion-button (click)="openFilter()">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/passport-02.svg'"></ion-icon>
    </ion-button>
    -->
    <ion-button (click)="showGeolocation()">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/mark.svg'"></ion-icon>
    </ion-button>
  </div>

</ion-content>
