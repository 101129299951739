import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components';
import { PremiumPage } from "..";
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ComponentsModule, TranslateModule
  ],
})
export class CalendarPage implements OnInit {

  // Variables
  data: any;
  date: any = new Date();
  show: boolean = false;
  daysInThisMonth: any;
  daysInLastMonth: any;
  daysInNextMonth: any;
  currentDate: any;
  calendarDate: any;
  activeDay: any;
  premium: boolean = false;
  planned: any = [{'planned': ''}];
  title: string = '';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalCalendar: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Calendar');
    this.events.subscribeEvent('calendar', () => {
      this.getData();
    });
    this.activeDay = this.date.getDate();
    this.setMonth();
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('calendar');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/me/calendar');
    });
  }

  //----------------------------------------------------------------------------
  // Set Month
  //----------------------------------------------------------------------------

  setMonth() {
    this.daysInThisMonth = new Array();
    this.daysInLastMonth = new Array();
    this.daysInNextMonth = new Array();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    this.title = monthNames[this.date.getMonth()] + ' ' + this.date.getFullYear();
    this.premium = this.date.getMonth() === new Date().getMonth() ? true : false;
    this.currentDate = new Date().getDate();

    var firstDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDay();
    var prevNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();
    for (var i = prevNumOfDays - (firstDayThisMonth - 1); i <= prevNumOfDays; i++) {
      this.daysInLastMonth.push(i);
    }

    var thisNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
    for (var j = 0; j < thisNumOfDays; j++) {
      this.daysInThisMonth.push(j + 1);
    }

    var lastDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDay();
    for (var k = 0; k < (6 - lastDayThisMonth); k++) {
      this.daysInNextMonth.push(k + 1);
    }
    var totalDays = this.daysInLastMonth.length + this.daysInThisMonth.length + this.daysInNextMonth.length;
    if (totalDays < 36) {
      for (var l = (7 - lastDayThisMonth); l < ((7 - lastDayThisMonth) + 7); l++) {
        this.daysInNextMonth.push(l);
      }
    }
    this.selectDay(this.currentDate);
    this.ref.detectChanges();
  }

  //----------------------------------------------------------------------------
  // Last Month
  //----------------------------------------------------------------------------

  lastMonth() {
    this.firebase.setEvent('calendar_last');
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0);
    this.setMonth();
    this.selectDay(this.activeDay);
  }

  //----------------------------------------------------------------------------
  // Next Month
  //----------------------------------------------------------------------------

  nextMonth() {
    this.firebase.setEvent('calendar_next');
    this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0);
    this.setMonth();
    this.selectDay(this.activeDay);
  }

  //----------------------------------------------------------------------------
  // Check Day
  //----------------------------------------------------------------------------

  checkDay(day: string) {
    var hasEvent = 'hide';
    this.calendarDate = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + this.activeDay).slice(-2);
    var monthStart = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + day).slice(-2) + ' 00:00:00';
    var monthEnd = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + day).slice(-2) + ' 23:59:59';
    if (this.data?.calendar) {
      this.data?.calendar.forEach((event: any) => {
        if ((event.planned >= monthStart) && (event.planned <= monthEnd)) {
          hasEvent = event.status;
          if (event.error) hasEvent = hasEvent + ' error';
        }
      });
    }
    return hasEvent;
  }

  //----------------------------------------------------------------------------
  // Select Day
  //----------------------------------------------------------------------------

  selectDay(day: string) {
    this.firebase.setEvent('calendar_day');
    
    this.activeDay = day;
    this.calendarDate = this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-' + ('0' + this.activeDay).slice(-2);
    this.planned.planned = this.calendarDate;
  }

  //----------------------------------------------------------------------------
  // Open Premium
  //----------------------------------------------------------------------------

  openPremium() {
    this.firebase.setEvent('calendar_premium');
    this.ionic.openPage(PremiumPage, 'premium');
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalCalendar.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
      await this.ionic.setTimeout(500);
      await this.modalCalendar.dismiss();
      this.events.refreshPages();
    }
  }

  //----------------------------------------------------------------------------
  // Refresh Screen
  //----------------------------------------------------------------------------

  async doRefresh(e: any) {
    this.firebase.setEvent('calendar_refresh');
    setTimeout(async () => {
      this.getData(); 
      e.target.complete();
    }, 600);
  }
}
