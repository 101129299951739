<ion-header mode="ios">
  <ion-toolbar>
    <ion-title>
      <div class="switcher {{ capacitor.scheme }}" (click)="openPopover($event)">{{ title | translate }}</div>
    </ion-title>
    <ion-popover #switcher trigger-action="click" side="bottom" alignment="end">
      <ng-template>
        <ion-item button detail="false" lines="none" [class.active]="type == 'goals'" (click)="switchContent('goals')">
          <ion-icon class="default" [src]="'/assets/icons/' + capacitor.scheme + '/target-04.svg'"></ion-icon>
          <ion-icon class="selected" [src]="'/assets/icons/color/target-04.svg'"></ion-icon>
          <ion-label>{{ 'MyGoals' | translate }}</ion-label>
          <ion-icon class="check" [src]="'/assets/icons/color/active.svg'" slot="end"></ion-icon>
        </ion-item>
        <ion-item button detail="false" lines="none" detail="false" [class.active]="type == 'places'" (click)="switchContent('places')">
          <ion-icon class="default" [src]="'/assets/icons/' + capacitor.scheme + '/globe-05.svg'"></ion-icon>
          <ion-icon class="selected" [src]="'/assets/icons/color/globe-05.svg'"></ion-icon>
          <ion-label>{{ 'MyPlaces' | translate }}</ion-label>
          <ion-icon class="check" [src]="'/assets/icons/color/active.svg'" slot="end"></ion-icon>
        </ion-item>
        <ion-item button detail="false" lines="none" detail="false" [class.active]="type == 'friends'" (click)="switchContent('friends')">
          <ion-icon class="default" [src]="'/assets/icons/' + capacitor.scheme + '/users-03.svg'"></ion-icon>
          <ion-icon class="selected" [src]="'/assets/icons/color/users-03.svg'"></ion-icon>
          <ion-label>{{ 'MyFriends' | translate }}</ion-label>
          <ion-icon class="check" [src]="'/assets/icons/color/active.svg'" slot="end"></ion-icon>
        </ion-item>
        <!-- FUTURE
        <ion-item button detail="false" lines="none" detail="false" [class.active]="type == 'journal'" (click)="switchContent('friends')">
          <ion-icon class="default" [src]="'/assets/icons/' + capacitor.scheme + '/book-open-01.svg'"></ion-icon>
          <ion-icon class="selected" [src]="'/assets/icons/color/book-open-01.svg'"></ion-icon>
          <ion-label>{{ 'Journal' | translate }}</ion-label>
          <ion-icon class="check" [src]="'/assets/icons/color/active.svg'" slot="end"></ion-icon>
        </ion-item>
        -->
      </ng-template>
    </ion-popover>
    <ion-buttons slot="end">
      <!-- FUTURE
      <ion-button (click)="openShare()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/share-01.svg'"></ion-icon>
      </ion-button>
      -->
      <ion-button (click)="openInvite()" *ngIf="type == 'friends'">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/users-plus.svg'"></ion-icon>
      </ion-button>
      <ion-button (click)="openPassport()" *ngIf="type == 'places'">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/trophy-01.svg'"></ion-icon>
      </ion-button>
      <ion-button class="expand" (click)="expandView()" [disabled]="this.expanded">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/layout-bottom.svg'" [hidden]="this.mapbox.mapBreakpoint == pageMap"></ion-icon>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/layout-top.svg'" [hidden]="this.mapbox.mapBreakpoint == pageMax"></ion-icon>
      </ion-button>
      <ion-button class="expand" (click)="setMaximize()" [disabled]="this.mapbox.mapBreakpoint != pageMax">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/maximize-01.svg'" [hidden]="expanded"></ion-icon>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/minimize-01.svg'" [hidden]="!expanded"></ion-icon>
      </ion-button>
      <ion-button aria-label="Account" class="avatar" [class.noimg]="avatar" [style.background-image]="avatar ? 'url('+ avatar +')' : ''" (click)="openAccount()">
        <div class="badge" *ngIf="!data?.verified && data"></div>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/settings-02.svg'" *ngIf="!avatar"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content>

  <ion-skeleton-text *ngIf="!data" animated></ion-skeleton-text>

  <!--------------------------------------------------------------------------->
  <!-- Banners -->
  <!--------------------------------------------------------------------------->
  
  <div *ngIf="data">

    <!-- Notifications -->

    <ion-grid class="message" *ngIf="data && data?.invites">
      <ion-list>
        <button ion-button class="cta big notifications" (click)="openNotifications()">
          <ion-icon src="/assets/icons/color/bell-01.svg"></ion-icon>
          <span>{{ 'NotificationsNew' | translate }}</span>
        </button>
      </ion-list>
    </ion-grid>

    <!-- Maximum -->

    <ion-grid class="message" *ngIf="data && !data?.premium && data?.maximum">
      <ion-list>
        <button ion-button aria-label="Instagram" class="cta big premium" (click)="openPremium()">
          <ion-icon src="/assets/icons/dark/check-verified-02.svg"></ion-icon>
          <span>{{ 'PremiumGo' | translate }}</span>
        </button>
      </ion-list>
    </ion-grid>
  </div>

  <!--------------------------------------------------------------------------->
  <!-- Goals -->
  <!--------------------------------------------------------------------------->

  <div *ngIf="type == 'goals'">

    <!-- Start -->

    <ion-grid *ngIf="data?.trips?.length == 0 && data?.buckets?.length == 0 && data?.goals?.length == 0">
      <ion-list>
        <div class="start">
          <div class="title">{{ 'StartGoals' | translate }}</div>
          <div class="text">{{ 'StartGoalsText' | translate }}</div>
          <div class="buttons">
            <ion-button mode="ios" class="tertiary" (click)="openTour()">
              <ion-icon [src]="'/assets/icons/color/message-question-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartHelp' | translate }}</ion-label>
            </ion-button>
            <ion-button mode="ios" class="primary" (click)="openManage('goal')">
              <ion-icon [src]="'/assets/icons/color/plus-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartGoal' | translate }}</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-list>
    </ion-grid>

    <!-- Trips -->

    <ion-grid *ngIf="data && data?.trips?.length != 0">
      <app-header title="Trips" type="trips" [edit]="true" [sorting]="data?.sort[0]?.trips" *ngIf="data?.trips?.length != 0"></app-header>
      <ion-list class="trips padding">
        <app-card [data]="data" [cache]="true" type="trip" display="trip" *ngFor="let data of data?.trips; index as i; trackBy: ionic.trackItem"></app-card>
      </ion-list>
    </ion-grid>

    <!-- Lists -->

    <ion-grid *ngIf="data && data?.buckets?.length != 0">
      <app-header title="Lists" type="buckets" [edit]="true" [sorting]="data?.sort[0]?.buckets" *ngIf="data?.buckets?.length != 0"></app-header>
      <ion-list class="lists padding">
        <app-card [data]="data" [cache]="true" type="bucket" display="bucket" *ngFor="let data of data?.buckets; index as i; trackBy: ionic.trackItem"></app-card>
      </ion-list>
    </ion-grid>

    <!-- Goals -->

    <ion-grid *ngIf="data && data?.goals?.length != 0">
      <app-header title="Goals" type="goals" [edit]="true" [sorting]="data?.sort[0]?.goals" *ngIf="data?.goals?.length != 0"></app-header>
      <ion-list>
        <div class="list" *ngFor="let data of items?.goals; index as i; trackBy: ionic.trackItem">
          <app-item [data]="data" [cache]="true" type="goal" [options]="true" *ngIf="!hide || !data?.listed" ></app-item>
        </div>
      </ion-list>
    </ion-grid>
  
    <!-- Infinite -->
  
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" *ngIf="items && data?.goals?.length >= 50">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

  </div>

  <!--------------------------------------------------------------------------->
  <!-- Places -->
  <!--------------------------------------------------------------------------->

  <div *ngIf="type == 'places'">
    
    <!-- Start -->

    <ion-grid *ngIf="data?.places?.length == 0">
      <ion-list>
        <div class="start">
          <div class="title">{{ 'StartPlaces' | translate }}</div>
          <div class="text">{{ 'StartPlacesText' | translate }}</div>
          <div class="buttons">
            <ion-button mode="ios" class="tertiary" (click)="openTour()">
              <ion-icon [src]="'/assets/icons/color/message-question-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartHelp' | translate }}</ion-label>
            </ion-button>
            <ion-button mode="ios" class="primary" (click)="openSearch()">
              <ion-label>{{ 'StartPlace' | translate }}</ion-label>
              <ion-icon [src]="'/assets/icons/color/plus-circle.svg'" [hidden]="expanded"></ion-icon>
            </ion-button>
          </div>
        </div>
      </ion-list>
    </ion-grid>

    <!-- Places -->
  
    <ion-grid *ngIf="data && data?.places?.length != 0">
      <app-header title="Places" type="places" [edit]="true"></app-header>
      <ion-skeleton-text *ngIf="!data?.places" animated></ion-skeleton-text>
      <ion-list>
        <app-item [data]="data" *ngFor="let data of items?.places; index as i; trackBy: ionic.trackItem"></app-item>
      </ion-list>
    </ion-grid>
  
    <!-- Infinite -->
  
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" *ngIf="items && data?.places?.length >= 50">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>

  <!--------------------------------------------------------------------------->
  <!-- Friends -->
  <!--------------------------------------------------------------------------->

  <div *ngIf="type == 'friends'">

    <!-- Start -->

    <ion-grid *ngIf="data?.friends?.length == 0">
      <ion-list>
        <div class="start">
          <div class="title">{{ 'StartFriends' | translate }}</div>
          <div class="text">{{ 'StartFriendsText' | translate }}</div>
          <div class="buttons">
            <ion-button mode="ios" class="tertiary" (click)="openTour()">
              <ion-icon [src]="'/assets/icons/color/message-question-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartHelp' | translate }}</ion-label>
            </ion-button>
            <ion-button mode="ios" class="primary" (click)="openInvite()">
              <ion-icon [src]="'/assets/icons/color/plus-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartInvite' | translate }}</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-list>
    </ion-grid>

    <!-- Friends -->

    <ion-grid *ngIf="data?.friends?.length != 0">
      <app-header title="Friends" type="friends"></app-header>
      <ion-skeleton-text *ngIf="!data?.friends" animated></ion-skeleton-text>
      <ion-list>
        <app-item [data]="data" type="friends" *ngFor="let data of items?.friends; index as i; trackBy: ionic.trackItem"></app-item>
      </ion-list>
    </ion-grid>
  
    <!-- Infinite -->
  
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" *ngIf="items && data?.friends?.length >= 50">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>

  <!--------------------------------------------------------------------------->
  <!-- Journal -->
  <!--------------------------------------------------------------------------->

  <div *ngIf="type == 'journal'">

    <!-- Start -->

    <ion-grid *ngIf="data?.journal?.length == 0">
      <ion-list>
        <div class="start">
          <div class="title">{{ 'StartJournal' | translate }}</div>
          <div class="text">{{ 'StartJournalText' | translate }}</div>
          <div class="buttons">
            <ion-button mode="ios" class="tertiary" (click)="openTour()">
              <ion-icon [src]="'/assets/icons/color/message-question-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartHelp' | translate }}</ion-label>
            </ion-button>
            <ion-button mode="ios" class="primary" (click)="openManage('post')">
              <ion-icon [src]="'/assets/icons/color/plus-circle.svg'" [hidden]="expanded"></ion-icon>
              <ion-label>{{ 'StartPost' | translate }}</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-list>
    </ion-grid>

    <!-- Posts -->

    <ion-grid *ngIf="data?.journal?.length != 0">
      <app-header title="Friends" type="friends"></app-header>
      <ion-skeleton-text *ngIf="!data?.journal" animated></ion-skeleton-text>
      <ion-list>
        <app-item [data]="data" type="posts" *ngFor="let data of items?.journal"></app-item>
      </ion-list>
    </ion-grid>
  
    <!-- Infinite -->
  
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)" *ngIf="items && data?.journal?.length >= 50">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>

  <!-- Refresh -->

  <div class="refresh" (click)="reloadView()">
    <ion-icon [src]="'/assets/icons/grey/refresh-ccw-05.svg'"></ion-icon>
  </div>

</ion-content>