<ion-list-header class="{{class}}">

  {{ title | translate }}

  <ion-row>

    <!-- Friends -->

    <ion-col *ngIf="type == 'friends'">
      <ion-icon src="/assets/icons/color/filter-funnel-02.svg" class="friends" (click)="openFriends()"></ion-icon>
    </ion-col>

    <!-- Assistant -->

    <ion-col *ngIf="edit && (type == 'trip' || type == 'bucket' || type == 'goal')">
      <ion-icon src="/assets/icons/color/stars-02.svg" class="assistant orange" (click)="openAssistant()"></ion-icon>
    </ion-col>

    <!-- Display

    <ion-col *ngIf="type == 'trip' || type == 'bucket'">
      <ion-icon src="/assets/icons/color/grid-01.svg" *ngIf="!capacitor.display" class="display" (click)="switchDisplay(true)"></ion-icon>
      <ion-icon src="/assets/icons/color/rows-01.svg" *ngIf="capacitor.display" class="display" (click)="switchDisplay(false)"></ion-icon>
    </ion-col> -->
    
    <!-- Locked -->

    <ion-col *ngIf="edit && (sorting == 'manual:asc' || sorting == 'manual:desc' || (sorting == 'planned:asc' && type == 'trip'))">
      <ion-icon src="/assets/icons/color/lock-unlocked-01.svg" *ngIf="!capacitor.lock" class="lock" (click)="lockOrder()"></ion-icon>
      <ion-icon src="/assets/icons/color/lock-01.svg" *ngIf="capacitor.lock" class="lock red" (click)="unlockOrder()"></ion-icon>
    </ion-col>

    <!-- Sorting -->

    <ion-col *ngIf="edit && sorting">
      <ion-icon src="/assets/icons/color/filter-lines.svg" class="more" (click)="openSort()"></ion-icon>
    </ion-col>

    <!-- Add -->

    <ion-col *ngIf="add">
      <ion-icon src="/assets/icons/color/plus-circle.svg" class="blue" (click)="openManage()"></ion-icon>
    </ion-col>

    <!-- Tour -->

    <div class="arrow" *ngIf="tour">
      {{ 'InviteTourToggle' | translate }}
      <ion-icon src="/assets/icons/grey/corner-right-down.svg"></ion-icon>
    </div>

    <!-- Spinner -->
     
    <ion-col *ngIf="spinner">
      <ion-spinner></ion-spinner>
    </ion-col>

  </ion-row>

</ion-list-header>

<!-- Assistant -->

<div class="bubble generating" (click)="openAssistant()" [class.task]="type == 'goal'" *ngIf="edit && generating">
  <ion-spinner name="dots"></ion-spinner> {{ 'AssistantGenerating' | translate }}
</div>