<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Account' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="bottom">

  <!--------------------------------------------------------------------------->
  <!-- Profile -->
  <!--------------------------------------------------------------------------->

  <ion-grid class="small">
    <ion-list class="group">
      <div class="box verify" (click)="openLogin()" *ngIf="!data?.view?.verified">
        <div class="title">
          <ion-icon src="/assets/icons/dark/shield-tick.svg"></ion-icon>
          {{ 'Verify' | translate }}
        </div>
        <div class="text">
          {{ 'VerifyInfo' | translate }}
        </div>
        <button ion-button class="cta small white red">
          {{ 'Verify' | translate }}
        </button>
      </div>
      <app-profile [data]="data?.view" type="account" [spinner]="data" (click)="openProfile()" *ngIf="data && data?.view?.verified"></app-profile>
      <app-item type="single" label="Private" icon="lock-01.svg" [disabled]="!data.verified" [toggle]="true" [data]="data?.view?.privacy" *ngIf="data" (changeToggle)="editPrivacy($event)"></app-item>
      <app-item type="single" label="Name" icon="user-circle.svg" [value]="data?.view?.name" [disabled]="!data.verified" *ngIf="data" (click)="editName()"></app-item>
      <app-item type="single" label="Email" icon="mail-01.svg" [value]="data?.view?.email" [disabled]="!data.verified" *ngIf="data" (click)="editEmail()"></app-item>
      <app-item type="single" label="Username" icon="at-sign.svg" [value]="data?.view?.username" [disabled]="!data.verified" *ngIf="data" (click)="editUsername()"></app-item>
      <app-item type="single" label="Avatar" icon="camera-01.svg" [spinner]="upload" [disabled]="!data.verified" *ngIf="data" (click)="editAvatar()"></app-item>
    </ion-list>
  </ion-grid>

  <!--------------------------------------------------------------------------->
  <!-- App -->
  <!--------------------------------------------------------------------------->

  <ion-grid class="small">
    <app-header title="App"></app-header>
    <ion-list class="group">
      <div class="box premium" (click)="openPremium()" *ngIf="!data?.view?.premium">
        <div class="title">
          <ion-icon src="/assets/icons/dark/star-01.svg"></ion-icon>
          {{ 'PremiumGo' | translate }}
        </div>
        <div class="text">
          {{ 'PremiumMaximum' | translate }}
        </div>
        <button ion-button class="cta small white">
          {{ 'PremiumGo' | translate }}
        </button>
      </div>
      <app-item type="single" label="Premium" icon="stars-01.svg" (click)="openPremium()" *ngIf="data?.view?.premium"></app-item>
      <!--<app-item type="single" label="AssistantAI" icon="star-02.svg" (click)="openAssistant()"></app-item>-->
      <app-item type="single" label="Calendar" icon="calendar-date.svg" (click)="openCalendar()"></app-item>
      <app-item type="single" label="SupportForm" icon="message-chat-circle.svg" (click)="openSupport()" *ngIf="data"></app-item>
      <app-item type="single" label="SupportFAQ" icon="life-buoy-01.svg" (click)="openHelp()"></app-item>
      <app-item type="single" label="SupportTour" icon="message-question-circle.svg" (click)="openTour()"></app-item>
    </ion-list>
  </ion-grid>

  <!--------------------------------------------------------------------------->
  <!-- Settings -->
  <!--------------------------------------------------------------------------->

  <ion-grid class="small">
    <app-header title="Settings"></app-header>
    <ion-list class="group">
      <app-item type="single" label="Notifications" icon="bell-01.svg" [toggle]="true" [data]="data?.view?.push" *ngIf="data" (changeToggle)="editNotifications($event)"></app-item>
      <app-item type="single" label="Translation" icon="flag-01.svg" select="language" [disabled]="!data" [data]="data?.view?.language" (changeSelect)="editLanguage($event)"></app-item>
      <app-item type="single" label="Appearance" icon="brush-03.svg" select="appearance" [disabled]="!data" [data]="data?.view?.appearance" (changeSelect)="editAppearance($event)"></app-item>
      <!--<app-item type="single" label="AppIcon" icon="face-id-square.svg" [value]="appicon" (click)="openSettings()"></app-item>-->
      <app-item type="single" label="Cache" icon="database-01.svg" (click)="clearCache()"></app-item>
    </ion-list>
  </ion-grid>

  <!--------------------------------------------------------------------------->
  <!-- Social -->
  <!--------------------------------------------------------------------------->

  <ion-grid class="small">
    <app-header title="Social"></app-header>
    <ion-list class="group">
      <app-item type="single" label="Rate" icon="star-01.svg" (click)="openStore()" *ngIf="capacitor.native"></app-item>
      <app-item type="single" label="Instagram" icon="social-instagram.svg" (click)="openInstagram()"></app-item>
      <app-item type="single" label="Threads" icon="social-threads.svg" (click)="openThreads()"></app-item>
      <app-item type="single" label="Refer" icon="share-01.svg" (click)="shareApp()"></app-item>
    </ion-list>
  </ion-grid>

  <!--------------------------------------------------------------------------->
  <!-- About -->
  <!--------------------------------------------------------------------------->
    
  <ion-grid class="small">
    <app-header title="About"></app-header>
    <ion-list class="group">
      <!--<app-item type="single" label="WhatsNew" icon="stars-01.svg" (click)="openUpdates()"></app-item>-->
      <app-item type="single" label="Terms" icon="file-02.svg" (click)="openTerms()"></app-item>
      <app-item type="single" label="Privacy" icon="shield-tick.svg" (click)="openPrivacy()"></app-item>
      <app-item type="single" label="Status" icon="bar-chart-10.svg" (click)="openStatus()"></app-item>
      <app-item type="single" label="Logout" icon="log-out-01.svg" (click)="logoutAccount()"></app-item>
    </ion-list>
  </ion-grid>

  <!--------------------------------------------------------------------------->
  <!-- Info -->
  <!--------------------------------------------------------------------------->
    
  <ion-grid class="small">
    <div class="copyright">
      <div class="version">{{ version }}</div>
      <div class="made">{{ 'Copyright' | translate }}</div>
      <div class="green" (click)="openGreen()">
        <ion-icon src="/assets/icons/color/lightbulb-05.svg"></ion-icon> {{ 'GreenEnergy' | translate }}
      </div>
      <button ion-button (click)="deleteAccount()" class="delete" *ngIf="data && capacitor.checkAuthorization">{{ 'AccountDelete' | translate }}</button>
    </div>
  </ion-grid>
  
</ion-content>