import { linkTickets, linkStays, linkFlights, linkRentals } from '../../../environments/environment';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, IonFab } from '@ionic/angular';
import { SearchPage, InvitePage, ManagePage, AssistantPage } from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  IonicService
} from '../../services';

@Component({
  standalone: false,
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {

  @ViewChild('fab') fab: IonFab | any;

  @Input() data: any = [];
  @Input() goals: any = [];
  @Input() edit: boolean = false;
  @Input() backdrop: boolean = false;
  @Input() bottom: boolean = false;
  @Input() type: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public modalCreate: ModalController,
    public firebase: FirebaseService,
    public ionic: IonicService,
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() { }

  //----------------------------------------------------------------------------
  // Open Create
  //----------------------------------------------------------------------------

  async openCreate() {
    this.capacitor.startHaptic();
    if(this.fab.activated) {
      this.firebase.setEvent('create_close');
      this.backdrop = false;
    } else {
      this.firebase.setEvent('create_open');
      this.backdrop = true;
    }
  }

  //----------------------------------------------------------------------------
  // Close Create
  //----------------------------------------------------------------------------

  async closeCreate() {
    this.firebase.setEvent('create_close');
    this.fab.close();
    this.backdrop = false;
  }

  //----------------------------------------------------------------------------
  // Open Manage
  //----------------------------------------------------------------------------

  async openManage(type: string) {
    this.firebase.setEvent('create_' + type);
    this.hideBackdrop();

    // Open
    if (this.edit) {
      this.ionic.openPage(ManagePage, type, '', this.data);
    } else {
      this.data.goals = this.goals;
      this.ionic.openPage(ManagePage, this.type, '', this.data);
    }
  }

  //----------------------------------------------------------------------------
  // Open Search
  //----------------------------------------------------------------------------

  async openSearch() {
    this.firebase.setEvent('create_place');
    this.ionic.openPage(SearchPage, 'map');
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Open Invite
  //----------------------------------------------------------------------------

  async openInvite() {
    this.firebase.setEvent('create_invite');
    this.ionic.openPage(InvitePage, 'invite');
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Open Assistant
  //----------------------------------------------------------------------------

  async openAssistant() {
    this.firebase.setEvent('create_assistant');
    this.ionic.openPage(AssistantPage, this.data?.type, this.data?.id);
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Open Tickets
  //----------------------------------------------------------------------------

  async openTickets() {
    this.firebase.setEvent('create_tickets');
    let query = this.data?.placeTitle ? '&q=' + this.data?.placeTitle : '';
    let startdate = this.data?.startDate ? '&date_from=' + this.data?.startDate : '';
    let endDate = this.data?.endDate ? '&date_to=' + this.data?.endDate : '';
    let lang = '&lng=' + this.capacitor.language;
    this.capacitor.openBrowser(linkTickets + query + startdate + endDate + lang);
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Open Stays
  //----------------------------------------------------------------------------

  async openStays() {
    this.firebase.setEvent('create_stays');
    let query = this.data?.placeTitle ? '&ss=' + this.data?.placeTitle : '';
    this.capacitor.openBrowser(linkStays + query);
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Open Flights
  //----------------------------------------------------------------------------

  async openFlights() {
    this.firebase.setEvent('create_flights');
    this.capacitor.openBrowser(linkFlights);
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Open Rentals
  //----------------------------------------------------------------------------

  async openRentals() {
    this.firebase.setEvent('create_rentals');
    this.capacitor.openBrowser(linkRentals);
    this.hideBackdrop();
  }

  //----------------------------------------------------------------------------
  // Hide Backdrop
  //----------------------------------------------------------------------------

  async hideBackdrop() {
    this.fab.activated = true;
    this.backdrop = false;
  }
}

