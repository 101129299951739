<ion-header #parallax mode="ios" class="view" [class.transparent]="data?.view?.id">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary" end>
      <ion-button class="filled label" (click)="showMap()" *ngIf="data?.view?.id && data?.view?.edit && (data?.view?.place || data?.view?.type == 'trip' || data?.view?.type == 'bucket')">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/layout-bottom.svg'" [hidden]="map"></ion-icon>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/layout-top.svg'" [hidden]="!map"></ion-icon>
        <ion-label [hidden]="map">{{ 'Map' | translate }}</ion-label>
        <ion-label [hidden]="!map">{{ 'List' | translate }}</ion-label>
      </ion-button>
      <ion-button class="filled dark" (click)="openManage()" *ngIf="data?.view?.edit">
        <ion-icon [src]="'/assets/icons/dark/edit-05.svg'"></ion-icon>
        <ion-label>{{ 'Edit' | translate }}</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-thumbnail class="parallax" [class.noimage]="!data?.view?.image">
    <cached-image [src]="data?.view?.image" [cache]="data?.view?.edit" radius="true" width="100%" height="100%" class="label mobile" *ngIf="data?.view?.image"></cached-image>
    <div class="image" *ngIf="!data?.view?.image"></div>
  </ion-thumbnail>
</ion-header>
  
<ion-content #content class="top footer" [class.noimage]="!data?.view?.image">

  <!-- Error -->

  <ion-grid *ngIf="data && data?.view && data?.view.length == 0">
    <ion-list class="items">
      <div class="accept">
        <img src="/assets/svg/visual-notfound.svg" width="200" height="200" class="visual" />
        <h1>{{ 'ViewError' | translate}}</h1>
        <div class="text">{{ 'ViewErrorMessage' | translate}}</div>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Views -->

  <ion-grid class="view" *ngIf="data && data?.view.length != 0 && data?.view?.type != 'user'">
      
    <!-- Heading -->
  
    <div class="heading">
      <ion-button class="share" (click)="openInvite()" *ngIf="data?.view?.owner && (type == 'bucket' || type == 'trip')">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/share-01.svg'"></ion-icon>
      </ion-button>
      <h1 class="title">{{ data?.view?.title }}</h1>
    </div>
  
    <!-- Meta -->
  
    <div class="information {{data?.view?.status}}" *ngIf="data?.view?.id && data?.view?.type != 'place'">
      <div class="text">
        <div class="types">
          <div class="avatar" [style.background-image]="'url(' + data?.view?.avatar + ')'"></div>
          <div class="type user" (click)="openProfile()" *ngIf="data?.view?.user">
            {{ data?.view?.username }}
          </div>
          <div class="type list" *ngIf="data?.view?.bucketTitle">
            • {{ data?.view?.bucketTitle }}
          </div>
          <div class="type list" *ngIf="data?.view?.tripTitle">
            • {{ data?.view?.tripTitle }}
          </div>
        </div>
        <div class="statistics" *ngIf="data?.view?.type == 'bucket' || data?.view?.type == 'trip' || data?.view?.type == 'user' || data?.view?.type == 'goal'">
          <span class="completed">{{ data?.view?.statsCompleted }}</span>
          <span class="open">/{{ data?.view?.statsTotal }}</span>
        </div>
      </div>
      <div class="bottom">
        <div class="progress">
          <div class="line {{data?.view?.status}}" [ngStyle]="{'width': data?.view?.statsProgress + '%'}" *ngIf="data?.view?.statsProgress"></div>
        </div>
      </div>
    </div>
  
    <!-- Description -->
  
    <div class="description">
      <div class="page-text-status success" *ngIf="data?.view?.status == 'completed'">{{ 'Completed' | translate }}</div>
      <div class="page-text-status primary" *ngIf="data?.view?.status == 'active'">{{ 'Travelling' | translate }}</div>
      <div class="page-text-html" [innerHTML]="data?.view?.story.split('\n').join('<br>')" *ngIf="data?.view?.story"></div>
      <div class="page-text-html" [innerHTML]="data?.view?.note.split('\n').join('<br>')" *ngIf="data?.view?.note"></div>
    </div>
  
    <!-- Links -->
  
    <div class="links">
      <div class="link" *ngIf="data?.view?.placeTitle">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + 'marker-pin-03.svg'"></ion-icon>
        {{ data?.view?.placeTitle }}
      </div>
      <div class="link {{data?.view?.videoType}}" (click)="openVideo()" *ngIf="data?.view?.video">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-tiktok.svg'" class="platform" *ngIf="data?.view?.videoType == 'tiktok'"></ion-icon>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-instagram.svg'" *ngIf="data?.view?.videoType == 'instagram'"></ion-icon>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-youtube.svg'" class="platform" *ngIf="data?.view?.videoType == 'youtube'"></ion-icon>
      </div>
      <div class="link" (click)="openWebsite()" rel="ugc" *ngIf="data?.view?.website">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/link-03.svg'"></ion-icon>
      </div>
      <div class="link more" (click)="toggleTags()" *ngIf="data?.view?.id">
        <ion-icon src="/assets/icons/color/tag-01.svg"></ion-icon>
        {{ 'ViewMore' | translate }}
      </div>
    </div>
  
    <!-- Tags -->
  
    <div class="tags" *ngIf="data?.view?.id">
      <ion-accordion-group #tags>
        <ion-accordion value="tags">
          <div slot="header"></div>
          <table slot="content">
            <tr *ngIf="data?.view?.placeTitle" (click)="showMap()">
              <td><ion-icon src="/assets/icons/color/marker-pin-01.svg"></ion-icon> {{ 'Location' | translate }}</td>
              <td> {{ data?.view?.placeTitle }}</td>
            </tr>
            <tr *ngIf="data?.view?.planned">
              <td><ion-icon src="/assets/icons/color/calendar-date.svg"></ion-icon> {{ 'Planned' | translate }}</td>
              <td>{{ ionic.setDate(data?.view?.planned) | date: 'd MMM y H:mm' }}</td>
            </tr>
            <tr *ngIf="data?.view?.finished">
              <td><ion-icon src="/assets/icons/color/calendar-check-01.svg"></ion-icon> {{ 'Finished' | translate }}</td>
              <td>{{ ionic.setDate(data?.view?.finished) | date: 'd MMM y H:mm' }}</td>
            </tr>
            <tr *ngIf="data?.view?.completed && data?.view?.status == 'completed'">
              <td><ion-icon src="/assets/icons/color/check-circle-broken.svg"></ion-icon> {{ 'Completed' | translate }}</td>
              <td>{{ ionic.setDate(data?.view?.completed) | date: 'd MMM y' }}</td>
            </tr>
            <tr *ngIf="data?.view?.reminder && data?.view?.edit">
              <td><ion-icon src="/assets/icons/color/bell-ringing-03.svg"></ion-icon> {{ 'Reminder' | translate }}</td>
              <td>{{ ionic.setDate(data?.view?.reminder) | date: 'd MMM y' }}</td>
            </tr>
            <tr *ngIf="data?.view?.startDate && data?.view?.endDate && data?.view?.type == 'trip'">
              <td><ion-icon src="/assets/icons/color/plane.svg"></ion-icon> {{ 'Dates' | translate }}</td>
              <td>{{ ionic.setDate(data?.view?.startDate) | date: 'd MMM y' }} - {{
                ionic.setDate(data?.view?.endDate) | date: 'd MMM y' }}</td>
            </tr>
            <tr *ngIf="data?.view?.budget && data?.view?.budget != '0' && data?.view?.edit">
              <td><ion-icon src="/assets/icons/color/wallet-02.svg"></ion-icon> {{ 'Budget' | translate }}</td>
              <td> {{ data?.view?.budget }}</td>
            </tr>
            <tr *ngIf="data?.view?.activity">
              <td><ion-icon src="/assets/icons/color/hearts.svg"></ion-icon> {{ 'Activity' | translate }}</td>
              <td> {{ data?.view?.activityTitle }}</td>
            </tr>
            <tr *ngIf="data?.view?.reservation">
              <td><ion-icon src="/assets/icons/color/file-05.svg"></ion-icon> {{ 'Reservation' | translate }}</td>
              <td> {{ data?.view?.reservation }}</td>
            </tr>
            <tr *ngIf="data?.view?.category && data?.view?.category != '0'">
              <td><ion-icon src="/assets/icons/color/folder.svg"></ion-icon> {{ 'Category' | translate }}</td>
              <td> {{ data?.view?.categoryTitle }}</td>
            </tr>
            <tr *ngIf="data?.view?.tickets">
              <td><ion-icon src="/assets/icons/color/ticket-01.svg"></ion-icon> {{ 'Tickets' | translate }}</td>
              <td>✓</td>
            </tr>
          </table>
        </ion-accordion>
      </ion-accordion-group>
    </div>
            
    <!-- Gallery -->
  
    <div class="gallery" *ngIf="data?.view?.images?.length != 0 && data?.view?.type != 'place'">
  
      <div class="image map {{data?.view?.type}}" (click)="showMap()" *ngIf="data?.view?.id && data?.view?.edit && (data?.view?.place || data?.view?.type == 'trip' || data?.view?.type == 'bucket')">
        <div class="icon"><ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/map-01.svg'"></ion-icon></div>
      </div>
  
      <div id="open-image1-{{data?.view?.id}}" expand="block" class="image" (click)="openGallery()" *ngIf="data?.view?.images?.image1">
        <cached-image [src]="data?.view?.images?.image1" [cache]="data?.view?.edit" *ngIf="data?.view?.images?.image1"></cached-image>
      </div>
      <div id="open-image2-{{data?.view?.id}}" expand="block" class="image" (click)="openGallery()" *ngIf="data?.view?.images?.image2">
        <cached-image [src]="data?.view?.images?.image2" [cache]="data?.view?.edit" *ngIf="data?.view?.images?.image2"></cached-image>
      </div>
      <div id="open-image3-{{data?.view?.id}}" expand="block" class="image" (click)="openGallery()" *ngIf="data?.view?.images?.image3">
        <cached-image [src]="data?.view?.images?.image3" [cache]="data?.view?.edit" *ngIf="data?.view?.images?.image3"></cached-image>
      </div>
  
      <ion-modal id="gallery" trigger="open-image1-{{data?.view?.id}}" *ngIf="data?.view?.images?.image1">
        <div class="fullscreen-img" *ngIf="data?.view?.images?.image1">
          <cached-image [src]="data?.view?.images?.image1" [cache]="data?.view?.edit"></cached-image>
          <div class="fullscreen-close" (click)="modalView.dismiss()">
            <ion-icon [src]="'/assets/icons/dark/x.svg'"></ion-icon>
          </div>
        </div>
      </ion-modal>
      <ion-modal id="gallery" trigger="open-image2-{{data?.view?.id}}" *ngIf="data?.view?.images?.image2">
        <div class="fullscreen-img" *ngIf="data?.view?.images?.image2">
          <cached-image [src]="data?.view?.images?.image2" [cache]="data?.view?.edit"></cached-image>
          <div class="fullscreen-close" (click)="modalView.dismiss()">
            <ion-icon [src]="'/assets/icons/dark/x.svg'"></ion-icon>
          </div>
        </div>
      </ion-modal>
      <ion-modal id="gallery" trigger="open-image3-{{data?.view?.id}}" *ngIf="data?.view?.images?.image3">
        <div class="fullscreen-img" *ngIf="data?.view?.images?.image3">
          <cached-image [src]="data?.view?.images?.image3" [cache]="data?.view?.edit"></cached-image>
          <div class="fullscreen-close" (click)="modalView.dismiss()">
            <ion-icon [src]="'/assets/icons/dark/x.svg'"></ion-icon>
          </div>
        </div>
      </ion-modal>
  
    </div>
  </ion-grid>

  <!-- Profile -->

  <app-profile type="user" [data]="data?.view" *ngIf="data && data?.view.length != 0 && data?.view?.type == 'user'"></app-profile>

  <!-- Trips -->
  
  <ion-grid *ngIf="data && data?.trips && data?.trips.length != 0 && data?.view?.type == 'user'">
    <app-header title="Trips"></app-header>
    <ion-list class="grid">
      <app-card [data]="trip" *ngFor="let trip of data?.trips; index as i; trackBy: ionic.trackItem"></app-card>
    </ion-list>
  </ion-grid>

  <!-- Buckets -->
  
  <ion-grid *ngIf="data && data?.buckets && data?.buckets.length != 0 && data?.view?.type == 'user'">
    <app-header title="Lists"></app-header>
    <ion-list class="grid">
      <app-card [data]="bucket" *ngFor="let bucket of data?.buckets; index as i; trackBy: ionic.trackItem"></app-card>
    </ion-list>
  </ion-grid>
  
  <!-- Goals -->

  <ion-grid *ngIf="data && data?.goals && type != 'task'">
    <app-header title="Goals" [id]="data?.view?.id" [type]="data?.view?.type" [edit]="data?.view?.edit" [sorting]="data?.view?.sort" [items]="data?.goals?.length" [generating]="data?.view?.generating"></app-header>
    <ion-list>
      <ion-reorder-group (ionItemReorder)="sort.reorderList($event, type, data?.goals)" disabled="false">
        <app-item [data]="goal" type="goal" [sort]="data?.view?.sort" [lock]="capacitor.lock" [reorder]="type" [options]="data?.view?.edit" *ngFor="let goal of data?.goals; index as i; trackBy: ionic.trackItem"></app-item>
      </ion-reorder-group>
    </ion-list>
  </ion-grid>

  <!-- Tasks -->

  <ion-grid *ngIf="data && data?.tasks && type == 'goal'">
    <app-header title="Tasks" [id]="data?.view?.id" [type]="data?.view?.type" [edit]="data?.view?.edit" [sorting]="data?.view?.sort" [items]="data?.tasks?.length" [generating]="data?.view?.assistant"></app-header>
    <ion-list>
      <ion-reorder-group (ionItemReorder)="sort.reorderList($event, type, data?.tasks)" disabled="false">
        <app-item [data]="task" [lock]="capacitor.lock" [reorder]="true" [sort]="data?.view?.sort" [options]="data?.view?.edit" *ngFor="let task of data?.tasks; index as i; trackBy: ionic.trackItem"></app-item>
      </ion-reorder-group>
    </ion-list>
  </ion-grid>
  
</ion-content>

<!-- Create -->

<app-create [type]="type" [goals]="data?.goals" [edit]="data?.view?.edit" [data]="data?.view" class="fixed"></app-create>