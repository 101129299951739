import { inject } from '@angular/core';
import { CapacitorService } from './services';
import { Routes } from '@angular/router';

export const routes: Routes = [

  // Root

  {
    path: '',
    canMatch: [() => inject(CapacitorService).checkAuthorization()],
    loadComponent: () => import('./pages/map/map.page').then(m => m.MapPage),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  
  // Pages
  
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'login/:id',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'invite/:id',
    loadComponent: () => import('./pages/invite/invite.page').then(m => m.InvitePage)
  },
  {
    path: 'view/:id',
    loadComponent: () => import('./pages/view/view.page').then(m => m.ViewPage)
  },

  // Redirects

  {
    path: '**',
    redirectTo: '/',
  }
];