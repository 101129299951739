import { appSync, pageMap, pageMax } from '../../../environments/environment';
import { Component, OnInit, NgZone, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { IonicModule, ModalController, IonAccordionGroup } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { interval, mergeMap } from 'rxjs';
import {
  CapacitorService,
  FirebaseService,
  SortingService,
  EventsService,
  CacheService,
  IonicService,
  HttpService,
  MapboxService,
} from '../../services';
import { 
  InvitePage,
  ManagePage
} from '..';

@Component({
  standalone: true,
  selector: 'page-view',
  templateUrl: './view.page.html',
  styleUrls: ['./view.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
})
export class ViewPage implements OnInit {

  @ViewChild('parallax', { read: ElementRef }) headerRef!: ElementRef;
  @ViewChild('tags', { static: false }) tags!: IonAccordionGroup;

  data: any;
  sync: any;
  places: any;
  id: string = '';
  type: string = '';
  map: boolean = false;
  breakpoint: number = pageMap;
  screen: string = this.type.charAt(0).toUpperCase() + this.type.slice(1);

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public modalView: ModalController,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public events: EventsService,
    public mapbox: MapboxService,
    public sort: SortingService,
    public renderer: Renderer2,
    public ionic: IonicService,
    public cache: CacheService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone,
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen(this.screen);
    this.events.subscribeEvent('view', (data) => { this.getData(data); });
    this.breakpoint = this.mapbox.mapBreakpoint ?? pageMap;
  }

  ionViewWillEnter() {
    this.getCache();
  }

  async ngOnDestroy() {
    //this.sync.unsubscribe();
    this.mapbox.hidePlaces();
    this.events.destroyEvent('view');
  }

  //----------------------------------------------------------------------------
  // Get Cache
  //----------------------------------------------------------------------------

  async getCache() {
    this.zone.run(async () => {
      this.data = await this.cache.getCache(this.id);
      this.places = this.data?.places;
      this.ref.detectChanges();
      this.initParallax();
      this.getData();
      //this.syncData();
    });
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData(sort: string = '') {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/view/' + this.id + sort, this.id);
      if(this.data?.places?.features.length != 0 && this.data?.view?.edit) {
      }
      this.ref.detectChanges();
    });
  }

  //----------------------------------------------------------------------------
  // Sync Data
  //----------------------------------------------------------------------------

  async syncData() {
    this.zone.run(async () => {
      this.sync = interval(appSync).pipe(mergeMap(async () =>
        await this.http.getRequest('/view/' + this.id, this.id)
      )).subscribe(data => { this.data = data; });
    });
  }

  //----------------------------------------------------------------------------
  // Open Manage
  //----------------------------------------------------------------------------

  async openManage() {
    this.firebase.setEvent('view_edit');
    this.ionic.openPage(ManagePage, this.data?.view?.type, this.data?.view?.id);
  }

  //----------------------------------------------------------------------------
  // Show Map
  //----------------------------------------------------------------------------

  async showMap() {
    this.firebase.setEvent('view_map');

    // Minimize
    this.map = !this.map;
    if(this.map) {
      this.mapbox.showPlaces(this.data);
      this.firebase.setEvent('view_map_open');
      this.breakpoint = this.mapbox.mapBreakpoint;
      const currentBreakpoints = this.ionic.modal.breakpoints || [];
      if (!currentBreakpoints.includes(pageMap)) {
        this.ionic.modal.breakpoints = [...currentBreakpoints, pageMap].sort();
      }
      this.ionic.modal.setCurrentBreakpoint(pageMap);
      this.mapbox.mapModal.setCurrentBreakpoint(pageMap);
      const modals = document.querySelectorAll('ion-modal#view');
      modals.forEach((modal: any) => {
        if (modal.setCurrentBreakpoint) {
          modal.setCurrentBreakpoint(pageMap);
        }
      });
    } else {
      this.firebase.setEvent('view_map_close');
      this.ionic.modal.breakpoints = this.ionic.modal.breakpoints.filter((bp: number) => bp !== pageMap);
      this.mapbox.mapModal.setCurrentBreakpoint(this.breakpoint);
      const modals = document.querySelectorAll('ion-modal#view');
      modals.forEach((modal: any) => {
        if (modal.setCurrentBreakpoint) {
          modal.setCurrentBreakpoint(pageMax);
        }
      });
    }
  }

  //----------------------------------------------------------------------------
  // Open Invite
  //----------------------------------------------------------------------------

  async openInvite() {
    this.firebase.setEvent('view_invite');
    this.ionic.openPage(InvitePage, this.data?.view?.type, this.data?.view?.id);
  }

  //----------------------------------------------------------------------------
  // Open Share
  //----------------------------------------------------------------------------

  async openShare() {
    this.firebase.setEvent('view_share');
    if (await this.capacitor.isNative()) {
      await this.capacitor.openShare(
        this.data?.view?.share, this.data?.view?.title
      );
    } else {
      await this.capacitor.setClipboard(
        this.data?.view?.share, this.data?.view?.title
      );
      await this.ionic.showAlert(
        this.translate.instant('Share'),
        this.translate.instant('Copied')
      );
    }
  }

  //----------------------------------------------------------------------------
  // Toggle Tags
  //----------------------------------------------------------------------------

  toggleTags = () => {
    const accordion = this.tags;
    accordion.value = accordion.value === 'tags' ? undefined : 'tags';
  };

  //----------------------------------------------------------------------------
  // Open Video
  //----------------------------------------------------------------------------

  async openVideo() {
    this.firebase.setEvent('view_video');
    await this.capacitor.openBrowser(this.data?.view?.video);
  }

  //----------------------------------------------------------------------------
  // Open Website
  //----------------------------------------------------------------------------

  async openWebsite() {
    this.firebase.setEvent('view_website');
    await this.capacitor.openBrowser(this.data?.view?.website);
  }

  //----------------------------------------------------------------------------
  // Open Ticket
  //----------------------------------------------------------------------------

  async openTicket() {
    this.firebase.setEvent('view_ticket');
    await this.capacitor.openBrowser(this.data?.view?.ticket);
  }

  //----------------------------------------------------------------------------
  // Open Gallery
  //----------------------------------------------------------------------------

  async openGallery() {
    this.firebase.setEvent('view_gallery');
  }

  //----------------------------------------------------------------------------
  // Open Profile
  //----------------------------------------------------------------------------

  async openProfile() {
    this.firebase.setEvent('view_profile');
    this.ionic.openPage(ViewPage, this.data?.view?.type, this.data?.view?.user);
  }

  //----------------------------------------------------------------------------
  // Open Information
  //----------------------------------------------------------------------------

  async openInformation() {
    this.firebase.setEvent('view_information');
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalView.getTop();
    if (modal) {
      this.events.refreshPages();
      modal.setCurrentBreakpoint(0);
      await this.ionic.setTimeout(500);
      await this.modalView.dismiss();
      this.mapbox.hidePlaces();
      this.mapbox.mapModal.setCurrentBreakpoint(this.breakpoint ?? pageMap);
    }
  }

  //----------------------------------------------------------------------------
  // Initalize Parallax
  //----------------------------------------------------------------------------

  initParallax() {
    const parentElement = this.headerRef.nativeElement.parentElement;
    const ionContent = parentElement.querySelector('ion-content');
    const ionHeader = this.headerRef.nativeElement;
    const ionToolbar = ionHeader.querySelector('ion-toolbar');
    const parallaxImage = ionHeader.querySelector('.parallax');
    const innerScroll = ionContent.shadowRoot.querySelector('.inner-scroll');

    // Scroll
    if (innerScroll) {
      innerScroll.addEventListener('scroll', () => {
        window.requestAnimationFrame(() => {
          let headerInit = 350;
          let scrollTopPx = innerScroll.scrollTop;
          let headerOffset = ionToolbar.offsetHeight;
          let headerOpacity = (headerInit - scrollTopPx - headerOffset) / (headerInit - headerOffset);
          let headerHeight = headerInit - scrollTopPx;
          headerOpacity = Math.max(headerOpacity, 0);
          headerHeight = Math.max(headerHeight, headerOffset);
          this.renderer.setStyle(parallaxImage, 'height', `${headerHeight}px`);
          this.renderer.setStyle(parallaxImage, 'opacity', `${headerOpacity}`);
          if(headerOpacity == 0) {
            this.renderer.addClass(ionToolbar, 'border');
          } else {
            this.renderer.removeClass(ionToolbar, 'border');
          }
        });
      });
    }
  }
}
