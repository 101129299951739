import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { CardComponent } from './card/card.component';
import { CreateComponent } from './create/create.component';
import { HeaderComponent } from './header/header.component';
import { ImageComponent } from './image/image.component';
import { ItemComponent } from './item/item.component';
import { PassportComponent } from './passport/passport.component';

@NgModule({ 
	declarations: [
		CardComponent,
		CreateComponent,
		HeaderComponent,
  	ImageComponent,
		ItemComponent,
		PassportComponent
	],
	imports: [
		RouterModule,
		IonicModule,
		FormsModule,
		CommonModule,
		TranslateModule.forChild(),
	],
	exports: [
		CardComponent,
		CreateComponent,
		HeaderComponent,
  	ImageComponent,
		ItemComponent,
		PassportComponent
	]
})
export class ComponentsModule { }
