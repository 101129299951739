import { appReview, appDebug } from '../../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InAppReview } from '@capacitor-community/in-app-review';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Preferences } from '@capacitor/preferences';
import { AppLauncher } from '@capacitor/app-launcher';
import { Geolocation } from '@capacitor/geolocation';
import { Clipboard } from '@capacitor/clipboard';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { FirebaseService, IonicService } from '..';

@Injectable({
  providedIn: 'root'
})
export class CapacitorService {

  //----------------------------------------------------------------------------
  // Global
  //----------------------------------------------------------------------------

  public authorization: string = '';
  public appearance: string = '';
  public scheme: string = '';
  public language: string = '';
  public platform: string = '';
  public push: string = '';
  public native: boolean = false;
  public rating: boolean = false;
  public tour: boolean = false;
  public map: boolean = false;
  public goals: boolean = false;
  public display: boolean = false;
  public lock: boolean = false;
  public dark: boolean = false;
  public desktop: boolean = false;
  public mobile: boolean = false;
  public fullscreen: boolean = false;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public location: Location,
    public ionic: IonicService,
    public router: Router,
    public plt: Platform
  ) { }

  //----------------------------------------------------------------------------
  // Initialize Application
  //----------------------------------------------------------------------------

  async initApplication() {

    // Global
    this.rating = await this.getStorage(appReview) ? false : true;
    this.goals = await this.getStorage('Goals') ? true : false;
    this.map = await this.getStorage('TourPlaces') ? false : true;
    this.display = await this.getStorage('Display') ? true : false;
    this.lock = await this.getStorage('Lock') ? true : false;
    this.language = await this.getStorage('Language') ?? '';
    this.push = await this.getStorage('Push') ?? '';
    this.platform = await this.getPlatform();
    this.native = await this.isNative();
    this.desktop = this.plt.is('desktop');
    this.mobile = this.plt.is('capacitor');
  }

  //----------------------------------------------------------------------------
  // Initalize Appearance
  //----------------------------------------------------------------------------

  async initAppearance() {
    let scheme = window.matchMedia("(prefers-color-scheme: dark)");
    scheme.addEventListener('change', (e) => this.checkAppearance(e.matches));
    this.checkAppearance(scheme.matches);
  }

  //----------------------------------------------------------------------------
  // Get Appearance
  //----------------------------------------------------------------------------

  async getAppearance() {
    return await this.getStorage('Appearance') ?? 'system';
  }

  //----------------------------------------------------------------------------
  // Set Appearance
  //----------------------------------------------------------------------------

  async setAppearance(scheme: string) {
    if (scheme != 'system') await this.setStorage('Appearance', scheme);
    if (scheme == 'system') await this.removeStorage('Appearance');
    this.initAppearance();
    this.checkAppearance();
    return await this.getAppearance();
  }

  //----------------------------------------------------------------------------
  // Check Appearance
  //----------------------------------------------------------------------------

  async checkAppearance(toggle: boolean = false) {
    let override = await this.getStorage('Appearance');
    this.scheme = override ? override : toggle ? 'dark' : 'light';
    document.documentElement.classList.toggle(
      'ion-palette-dark', this.scheme == 'dark' ? true : false
    );
  }

  //----------------------------------------------------------------------------
  // Get Language
  //----------------------------------------------------------------------------

  async getLanguage(selector?: string) {
    let language = 'en';

    // Device
    const device = await Device.getLanguageCode();
    if (device.value) language = device.value;

    // Storage
    if (this.language) language = this.language;

    // Browser
    const paths = ['en', 'fr', 'de', 'it', 'nl', 'pt', 'es'];
    const route = location.pathname.substring(1);
    if (paths.indexOf(route) >= 0) language = route;
    language = paths.includes(language) ? language : 'en';

    // Override
    if (selector) language = selector;

    // Set
    this.setStorage('Language', language);
    this.document.documentElement.lang = language;
    this.translate.setDefaultLang(language);
    this.translate.use(language);

    // Return
    this.language = language;
    return language;
  }

  //----------------------------------------------------------------------------
  // Get Authorization
  //----------------------------------------------------------------------------

  async getAuthorization() {
    return await SecureStoragePlugin.get({ key: 'Authorization' })
      .then(success => { return success['value']; })
      .catch(error => { return ''; });
  }

  //----------------------------------------------------------------------------
  // Check Authorization
  //----------------------------------------------------------------------------

  async checkAuthorization() {
    return await SecureStoragePlugin.get({ key: 'Authorization' })
      .then(success => { return true; })
      .catch(error => { return false; });
  }

  //----------------------------------------------------------------------------
  // Set Keychain
  //----------------------------------------------------------------------------

  async setAuthorization(value: string) {
    return await SecureStoragePlugin.set({
      key: 'Authorization',
      value: value
    });
  }

  //----------------------------------------------------------------------------
  // Is Native
  //----------------------------------------------------------------------------

  async isNative() {
    return Capacitor.isNativePlatform();
  }

  //----------------------------------------------------------------------------
  // Get Platform
  //----------------------------------------------------------------------------

  async getPlatform() {
    return Capacitor.getPlatform();
  }

  //----------------------------------------------------------------------------
  // Get Info
  //----------------------------------------------------------------------------

  async getInfo() {
    return await Device.getInfo();
  }

  //----------------------------------------------------------------------------
  // Get Photos
  //----------------------------------------------------------------------------

  async getPhotos() {
    if (await Camera.checkPermissions()) {
      return await Camera.getPhoto({
        quality: 100,
        width: 1080,
        source: CameraSource.Photos,
        allowEditing: false,
        saveToGallery: true,
        resultType: CameraResultType.Uri
      });
    } else {
      return await Camera.requestPermissions();
    }
  }

  //----------------------------------------------------------------------------
  // Start Haptic
  //----------------------------------------------------------------------------

  async startHaptic() {
    if (Capacitor.isNativePlatform()) {
      return await Haptics.impact({
        style: ImpactStyle.Light
      });
    }
  }

  //----------------------------------------------------------------------------
  // Open Browser
  //----------------------------------------------------------------------------

  async openBrowser(url: string) {
    return await Browser.open({
      url: url.startsWith('http') ? url : 'https://' + url
    });
  }

  //----------------------------------------------------------------------------
  // Open Launcher
  //----------------------------------------------------------------------------

  async openLauncher(app: string, url: string) {
    const { value } = await AppLauncher.canOpenUrl({ url: app });
    if (await this.isNative() && value) {
      await AppLauncher.openUrl({ url: app });
    } else {
      await this.openBrowser(url);
    }
  }

  //----------------------------------------------------------------------------
  // Open Share
  //----------------------------------------------------------------------------

  async openShare(url: string, text: string) {
    return await Share.share({
      title: 'iBucket.app',
      text: text,
      url: url,
      dialogTitle: 'Share'
    });
  }

  //----------------------------------------------------------------------------
  // Set Clipboard
  //----------------------------------------------------------------------------

  async setClipboard(url: string, text: string) {
    return await Clipboard.write({
      string: text + ' ' + url
    });
  }

  //----------------------------------------------------------------------------
  // Get Storage
  //----------------------------------------------------------------------------

  async getStorage(key: string) {
    await Preferences.configure({ group: 'Storage' });
    const data: any = await Preferences.get({ key: key });
    return data['value'];
  }

  //----------------------------------------------------------------------------
  // Set Storage
  //----------------------------------------------------------------------------

  async setStorage(key: string, value: string) {
    await Preferences.configure({ group: 'Storage' });
    await Preferences.set({ key: key, value: value });
    return true;
  }

  //----------------------------------------------------------------------------
  // Remove Storage
  //----------------------------------------------------------------------------

  async removeStorage(key: string) {
    await Preferences.configure({ group: 'Storage' });
    await Preferences.remove({ key: key });
    return true;
  }

  //----------------------------------------------------------------------------
  // Clear Storage
  //----------------------------------------------------------------------------

  async clearStorage() {
    await SecureStoragePlugin.clear();
    await Preferences.configure({ group: 'Storage' });
    await Preferences.clear();
    return true;
  }

  //----------------------------------------------------------------------------
  // Request Review
  //----------------------------------------------------------------------------

  async requestReview(reviews: string) {
    if (!await this.getStorage(appReview) && reviews) {
      this.firebase.setEvent('review_request');
      await this.setStorage(appReview, 'hide');
      InAppReview.requestReview();
    }
  }

  //----------------------------------------------------------------------------
  // Request Geolocation
  //----------------------------------------------------------------------------

  async requestGeolocation() {

    // Web
    if (this.platform == 'web') {
      this.firebase.setEvent('geolocation_disabled');
      this.ionic.showAlert('Disabled', 'This feature currently works only on our iOS and Android apps.');
      return;
    }

    // Request
    try {
      const permissionStatus = await Geolocation.checkPermissions();
      if (permissionStatus.location === 'granted') {
        this.firebase.setEvent('geolocation_granted');
        return this.getGeolocation();

      } else if (permissionStatus.location === 'denied') {
        this.firebase.setEvent('geolocation_denied');
        this.ionic.showAlert('Geolocation', 'Location permissions are denied. Please enable them in settings.');
        return;

      } else {
        const permissionRequest = await Geolocation.requestPermissions();
        if (permissionRequest.location === 'granted') {
          this.firebase.setEvent('geolocation_granted');
          return this.getGeolocation();
        } else {
          this.firebase.setEvent('geolocation_required');
          this.ionic.showAlert('Geolocation', 'Location permissions are required to get your position.');
          return;
        }
      }
    } catch (error) {
      this.firebase.setEvent('geolocation_error');
      this.ionic.showAlert('Error', '' + error);
      return;
    }
  }

  //----------------------------------------------------------------------------
  // Get Geolocation
  //----------------------------------------------------------------------------

  async getGeolocation() {
    const coordinates = await Geolocation.getCurrentPosition();
    return {
      latitude: coordinates.coords.latitude,
      longitude: coordinates.coords.longitude,
    };
  }

  //----------------------------------------------------------------------------
  // Listener Notifications
  //----------------------------------------------------------------------------

  async initNotifications() {
    if (Capacitor.isNativePlatform()) {

      await PushNotifications.addListener('registration', token => {
        if (appDebug) console.log('[PUSH:TOKEN]', token.value);
        this.firebase.setEvent('notification_token');
        this.setStorage('Push', token.value);
        this.push = token.value;
      });
      
      await PushNotifications.addListener('registrationError', err => {
        if (appDebug) console.log('[PUSH:ERROR]', err.error);
        this.firebase.setEvent('notification_error');
      });
    
      await PushNotifications.addListener('pushNotificationReceived', push => {
        if (appDebug) console.log('[PUSH:NOTIFICATION]', push);
        this.firebase.setEvent('notification_received');
      });
    
      await PushNotifications.addListener('pushNotificationActionPerformed', push => {
        if (appDebug) console.log('[PUSH:NOTIFICATION]', push.actionId, push.inputValue);
        this.firebase.setEvent('notification_action');
      });

      await this.clearNotifications();
    }
  }

  //----------------------------------------------------------------------------
  // Register Notifications
  //----------------------------------------------------------------------------

  async registerNotifications() {
    if (Capacitor.isNativePlatform()) {

      // Permissions
      let status = await PushNotifications.checkPermissions();
      if (status.receive === 'prompt') {
        this.firebase.setEvent('notification_permission');
        status = await PushNotifications.requestPermissions();
      }

      // Register
      if (status.receive == 'granted') {
        this.firebase.setEvent('notification_granted');
        await PushNotifications.register();
      } else {
        this.firebase.setEvent('notification_denied');
      }
    }
  }

  //----------------------------------------------------------------------------
  // Get Token
  //----------------------------------------------------------------------------

  async getToken() {
    if (Capacitor.isNativePlatform()) {
      await this.registerNotifications();
      return await this.getStorage('Push');
    }
    return '';
  };

  //----------------------------------------------------------------------------
  // Local Notification
  //----------------------------------------------------------------------------

  async localNotification(date: any, title: string, body: string) {
    this.firebase.setEvent('notification_local');

    // Schedule
    if (Capacitor.isNativePlatform()) {
      return await LocalNotifications.schedule({
        notifications: [{
          title: title,
          body: body,
          id: new Date().getTime(),
          schedule: { at: new Date(date.replace(/\s/g, 'T')) }
        }]
      });
    }
    return '';
  }

  //----------------------------------------------------------------------------
  // Clear Notifications
  //----------------------------------------------------------------------------

  async clearNotifications() {
    if (Capacitor.isNativePlatform()) {
      this.removeStorage('Notifications');
      PushNotifications.removeAllDeliveredNotifications();
    }
  }
}
