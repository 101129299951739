<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Support' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
    
  <!-- Form -->

  <ion-grid>
    <ion-list class="form">
      <div class="warning" *ngIf="data && !data?.account[0]?.verified">
        {{ 'SupportVerified' | translate }}
        <a (click)="openLogin()">{{ 'Verify' | translate }}</a>
      </div>
      <ion-input type="email" mode="ios" class="left" [(ngModel)]="email" name="email" placeholder="{{ 'LoginEmail' | translate }}" [disabled]="data && data?.account[0]?.verified"></ion-input>
      <ion-textarea #input [(ngModel)]="message" class="message" autoGrow="true" autofocus="true" autocapitalize="sentences" mode="ios" placeholder="{{ 'SupportMessage' | translate }}"></ion-textarea>
      <button ion-button (click)="sendMessage()" class="cta medium blue shadow" [disabled]="!message || disable">
        <ion-icon src="/assets/icons/color/send-01.svg"></ion-icon> {{ 'Send' | translate }}
      </button>
    </ion-list>
    <div class="debug">
      {{ debug }}
    </div>
  </ion-grid>

</ion-content>